<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { Patient } from "frontend/interfaces/patient";
  import { formatDate, DateFormat } from "shared/utils/date-utils";

  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: { FormInput },
    props: {
      patient: {
        type: Object as PropType<Patient>,
        required: true,
      },
    },
    setup() {
      return {
        DateFormat,
        formatDate,
      };
    },
  });
</script>

<template>
  <div class="vue-component--patient-data">
    <h4 class="main-heading">Patientenstammdaten</h4>
    <FormInput
      label="Name Patient*in"
      disabled
      v-bind:model-value="patient.first_name + ' ' + patient.name"
    />
    <FormInput
      label="Geburtsdatum"
      disabled
      v-bind:model-value="
        formatDate(patient.date_of_birth, DateFormat.DateOnly)
      "
    />
    <FormInput
      label="Krankenkasse"
      disabled
      v-bind:model-value="patient.insurance_name"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .main-heading {
    text-transform: none;
    margin: 10px 0;
  }

  .link {
    padding: 10px 0;

    &,
    a,
    a:visited {
      color: colors.$color_link--text;

      font-size: 14px;
    }
  }
</style>
