<script lang="ts" setup>
  import { computed, onMounted, PropType, ref } from "vue";
  import { compareAsc, compareDesc, isAfter, isBefore } from "date-fns";
  import { useRouter } from "vue-router";
  import { AssertTrue, IsExact } from "conditional-type-checks";

  import { Absence as OriginalAbsence } from "frontend/interfaces/settings/absence";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { useFilter } from "frontend/uses/filter/use-filter";
  import {
    translateUnitType,
    inverseEnum,
    UnitTypeKey,
  } from "frontend/utils/translate-enum";
  import { UnitType } from "shared/static/enums.ts.erb";
  import { Person } from "frontend/interfaces/person";
  import { Cache } from "frontend/utils/request-cache";
  import { simpleCheckboxGroup } from "frontend/uses/filter/predefined/checkbox";
  import { simpleRow } from "frontend/uses/filter/predefined/flex";
  import { simpleSelect } from "frontend/uses/filter/predefined/select";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { omit } from "shared/utils/typescript-helper";

  import FilterContainer from "frontend/components/filter/FilterContainer.vue";
  import BaseListItem from "frontend/components/base/BaseListItem.vue";

  type OmmitedKeys = "persons" | "person_ids";
  const ommitedKeys = ["person_ids", "persons"] as const;

  // ATTENTION:
  // If you get an error here, you probably made some typing error.
  // please check first before changing here!
  // This is a saftey-net ASSERTION!
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- type assetion
  type assertionForOmmitedKeys = AssertTrue<
    IsExact<(typeof ommitedKeys)[number], OmmitedKeys>
  >;

  interface Absence extends Omit<OriginalAbsence, OmmitedKeys> {
    person: OriginalAbsence["persons"][number];
    person_id: OriginalAbsence["person_ids"][number];
  }

  const props = defineProps({
    absences: {
      type: Array as PropType<Array<OriginalAbsence>>,
      default: () => [],
    },
  });

  const persons = ref<Person[]>([]);
  const loadPersons = async () => {
    persons.value = await Cache.getCachedPersons();
  };
  onMounted(loadPersons);

  const listAbsences = computed<Array<Absence>>(() =>
    props.absences
      .map((absence) =>
        absence.persons.map((person) => ({
          ...omit<OriginalAbsence, OmmitedKeys>(absence, ommitedKeys),
          person,
          person_id: person.id,
        }))
      )
      .flat()
  );

  const formatAbsenceDate = (date: Date) =>
    formatDate(date, DateFormat.DateAndTime);
  const formatAbsenceReason = (
    reason: (typeof UnitType)[keyof typeof UnitType]
  ) => translateUnitType(inverseEnum(UnitType, reason)).label;
  const formatAbsenceNames = (absence: Absence) => absence.person.name;

  const sortOptions = [
    {
      id: "date-asc",
      label: "Nach Datum sortieren (aufsteigend)",
      compare: (a: Absence, b: Absence) =>
        compareAsc(a.absent_from, b.absent_from),
    },
    {
      id: "date-desc",
      label: "Nach Datum sortieren (absteigend)",
      compare: (a: Absence, b: Absence) =>
        compareDesc(a.absent_from, b.absent_from),
    },
    {
      id: "person-asc",
      label: "Nach Mitarbeiter sortieren (aufsteigend)",
      compare: (a: Absence, b: Absence) =>
        formatAbsenceNames(a).localeCompare(formatAbsenceNames(b)),
    },
    {
      id: "person-desc",
      label: "Nach Mitarbeiter sortieren (absteigend)",
      compare: (a: Absence, b: Absence) =>
        -formatAbsenceNames(a).localeCompare(formatAbsenceNames(b)),
    },
  ];
  const compareFnById = (
    id: string | null
  ): ((a: Absence, b: Absence) => number) =>
    (sortOptions.filter((opt) => opt.id === id)[0] || sortOptions[0]).compare;

  //FILTER
  const dateStatusOptions = [
    {
      id: "hide-past",
      label: "abgelaufene Abwesenheiten ausblenden",
      predicate: (absence: Absence): boolean =>
        isBefore(absence.absent_to, new Date()),
    },
    {
      id: "hide-active",
      label: "aktive Abwesenheiten ausblenden",
      predicate: (absence: Absence): boolean =>
        isBefore(absence.absent_from, new Date()) &&
        isAfter(absence.absent_to, new Date()),
    },
    {
      id: "hide-future",
      label: "zukünftige Abwesenheiten ausblenden",
      predicate: (absence: Absence): boolean =>
        isAfter(absence.absent_from, new Date()),
    },
  ];
  const dateStatusOptionPredicateByID = (
    id: PrimaryKey
  ): ((absence: Absence) => boolean) =>
    dateStatusOptions.filter((opt) => opt.id === id)[0]?.predicate ??
    (() => true);
  const { filteredCollection, filterContainerBinding } = useFilter(
    listAbsences,
    [
      simpleRow([
        simpleSelect(
          {},
          {
            frontend: (entries, selectedID) =>
              entries.sort(compareFnById(selectedID)),
          },
          { label: "Sortierung", options: sortOptions },
          "date-asc"
        ),
        simpleCheckboxGroup(
          {
            frontend: (entry: Absence, selectedIDs: Array<PrimaryKey>) =>
              selectedIDs.length === 0 ||
              selectedIDs.indexOf(entry.person_id) >= 0,
          },
          {
            label: "Mitarbeiter",
            options: computed(() =>
              persons.value.map((p) => ({
                id: p.id,
                label: p.name,
              }))
            ),
          }
        ),
        simpleCheckboxGroup(
          {
            frontend: (entry: Absence, selectedIDs: Array<PrimaryKey>) =>
              selectedIDs.length === 0 ||
              selectedIDs.indexOf(entry.reason.toString()) >= 0,
          },
          {
            label: "Grund der Abwesenheit",
            options: (Object.keys(UnitType) as UnitTypeKey[]).map((key) => ({
              id: UnitType[key].toString(),
              label: translateUnitType(key).label,
            })),
          }
        ),
        simpleCheckboxGroup(
          {
            frontend: (entry, selectedIDs) =>
              selectedIDs.reduce(
                (result, next) =>
                  result && !dateStatusOptionPredicateByID(next)(entry),
                true
              ),
          },
          {
            label: "Ausgeblendete Abwesenheiten",
            options: dateStatusOptions,
          },
          [dateStatusOptions[0].id]
        ),
      ]),
    ]
  );

  const router = useRouter();
  const showInCalendar = (absence: Absence) => {
    router.push({
      name: "calendar-day",
      params: { day: formatDate(absence.absent_from, DateFormat.DateOnlyISO) },
    });
  };
</script>

<template>
  <div v-if="absences.length > 0">
    <FilterContainer class="filter-container" v-bind="filterContainerBinding" />

    <div v-if="filteredCollection.length <= 0">
      <p><i>Keine Abwesenheiten vorhanden</i></p>
    </div>

    <div v-for="(absence, index) in filteredCollection" v-bind:key="index">
      <BaseListItem
        v-bind:title="formatAbsenceNames(absence)"
        v-bind:subtitle="
          formatAbsenceDate(absence.absent_from) +
          ' - ' +
          formatAbsenceDate(absence.absent_to)
        "
        v-bind:text="formatAbsenceReason(absence.reason) + '/' + absence.name"
        calendar-action
        flush
        v-on:show-item="showInCalendar(absence)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .filter-container {
    margin-top: 40px;
  }
</style>
