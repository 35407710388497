<script lang="ts" setup>
  import { toRefs } from "vue";

  import {
    defineShowUnitProps,
    useShowUnit,
  } from "frontend/uses/use-show-unit";
  import { usePending } from "frontend/uses/use-pending";
  import { destroyUnit } from "frontend/api/application/request-unit";
  import { useToasts } from "frontend/uses/use-toasts";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";

  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  const props = defineProps({
    ...defineShowUnitProps(),
    ...useAsModalChildProps(),
  });
  const emit = defineEmits(["refresh-needed"]);

  const { modelValue } = toRefs(props);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- modelValue just cant be null
  const { currentUnit } = useShowUnit(modelValue!);

  const { success: showSuccess, error: showError } = useToasts();
  const { isPending: isDeletionPending, doWithPending } = usePending();
  const doDeleteAbsence = async () => {
    if (!modelValue.value) return;

    await doWithPending(async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- it is asserted before that modelValue.value is NOT nullish
      const { success } = await destroyUnit(modelValue.value!, false, null);
      if (success) {
        showSuccess("Abwesenheit wurde gelöscht");
        props.asModalChild?.doClose(true);
        emit("refresh-needed");
      } else showError("Abwesenheit konnte nicht gelöscht werden");
    });
  };
</script>

<template>
  <div class="show-unit-absence">
    <h2 class="sticky-heading">Abwesenheit</h2>
    <template v-if="currentUnit">
      Die Abwesenheit ist für den Zeitraum von
      <strong>{{
        formatDate(currentUnit.from, DateFormat.DateAndTime)
      }}</strong>
      bis
      <strong>{{ formatDate(currentUnit.to, DateFormat.DateAndTime) }}</strong>
      eingetragen.
      <h5>Eingetragene Personen:</h5>
      <ul>
        <li
          v-for="participation in currentUnit.participations"
          v-bind:key="participation.id"
        >
          {{ participation.person_name }}
        </li>
      </ul>
      <BaseButton
        v-bind:disable="isDeletionPending"
        v-on:submit="doDeleteAbsence"
      >
        <BaseSpinner v-if="isDeletionPending" visual-inverted /><template v-else
          >Abwesenheit löschen</template
        >
      </BaseButton>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .show-unit-absence {
    min-width: 60vw;
  }
</style>
