<script lang="ts">
  import { computed, defineComponent } from "vue";
  import { v4 as uuid } from "uuid";
  import { RouteLocationRaw } from "vue-router";

  const SHOW_ALL_PAGES_UNTIL_MORE = 10; // pages
  const NUM_LEFT_PAD = 3; // number of pages left to the current page
  const NUM_RIGHT_PAD = NUM_LEFT_PAD; // number of pages right to the current page

  export default defineComponent({
    props: {
      currentPageNumber: {
        type: Number,
        default: 1,
      },
      totalPages: {
        type: Number,
        default: 1,
      },
      routeName: {
        type: String,
        required: true,
      },
      pageVariableName: {
        type: String,
        default: "pageNumber",
      },
    },
    setup(props) {
      const compactMode = computed(
        () => props.totalPages <= SHOW_ALL_PAGES_UNTIL_MORE
      );

      const entries = computed(() => {
        if (compactMode.value) {
          return Array.from({ length: props.totalPages }, (_, index) =>
            createPage(
              index + 1,
              props.currentPageNumber,
              props.routeName,
              props.pageVariableName
            )
          );
        } else {
          const left_items = Math.min(
            NUM_LEFT_PAD,
            Math.max(props.currentPageNumber - 1, 0)
          );
          const right_items = Math.min(
            NUM_RIGHT_PAD,
            props.totalPages - props.currentPageNumber
          );

          return [
            ...(props.currentPageNumber > 1
              ? [
                  createPage(
                    1,
                    props.currentPageNumber,
                    props.routeName,
                    props.pageVariableName,
                    "<< Erste"
                  ),
                ]
              : []),

            ...(left_items > 0
              ? [
                  createPage(
                    props.currentPageNumber - 1,
                    props.currentPageNumber,
                    props.routeName,
                    props.pageVariableName,
                    "< Vorherige"
                  ),
                  createPlaceholder("..."),
                ]
              : []),

            ...Array.from({ length: left_items }, (_, index) =>
              createPage(
                props.currentPageNumber - left_items + index,
                props.currentPageNumber,
                props.routeName,
                props.pageVariableName
              )
            ),
            createPage(
              props.currentPageNumber,
              props.currentPageNumber,
              props.routeName,
              props.pageVariableName
            ),
            ...Array.from({ length: right_items }, (_, index) =>
              createPage(
                props.currentPageNumber + index + 1,
                props.currentPageNumber,
                props.routeName,
                props.pageVariableName
              )
            ),

            ...(right_items > 0
              ? [
                  createPlaceholder("..."),
                  createPage(
                    props.currentPageNumber + 1,
                    props.currentPageNumber,
                    props.routeName,
                    props.pageVariableName,
                    "Nächste >"
                  ),
                ]
              : []),

            ...(props.currentPageNumber < props.totalPages
              ? [
                  createPage(
                    props.totalPages,
                    props.currentPageNumber,
                    props.routeName,
                    props.pageVariableName,
                    "Letzte >>"
                  ),
                ]
              : []),
          ];
        }
      });

      return {
        compactMode,
        entries,
      };
    },
  });

  interface Page {
    type: "page";
    key: string;
    to: RouteLocationRaw;
    label: string;
    active: boolean;
  }

  interface Placeholder {
    type: "placeholder";
    key: string;
    label: string;
  }

  function createPage(
    pageNumber: number,
    currentPageNumber: number,
    routeName: string,
    pageVariableName: string,
    forceLabel?: string
  ): Page {
    const label = forceLabel || pageNumber.toString();
    return {
      type: "page",
      key: label,
      active: pageNumber === currentPageNumber,
      to: {
        name: routeName,
        params: {
          [pageVariableName]: pageNumber,
        },
      },
      label,
    };
  }

  function createPlaceholder(label: string): Placeholder {
    return {
      type: "placeholder",
      key: uuid(),
      label,
    };
  }
</script>

<template>
  <div class="page-navigation">
    <div class="page-navigation__heading">Seiten:</div>
    <div
      v-for="entry in entries"
      v-bind:key="entry.key"
      class="page-navigation__entry"
    >
      <RouterLink
        v-if="entry.type === 'page'"
        class="page-navigation__link"
        v-bind:to="entry.to"
        v-bind:class="{ 'page-navigation__link--active': entry.active }"
        >{{ entry.label }}</RouterLink
      >
      <span
        v-else-if="entry.type === 'placeholder'"
        class="page-navigation__placeholder"
        >{{ entry.label }}</span
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .page-navigation {
    display: flex;
    justify-content: space-between;
  }

  .page-navigation__heading {
    font-weight: bold;
    font-size: 12px;
  }

  .page-navigation__entry {
    font-size: 12px;
  }

  .page-navigation__link {
    color: colors.$color_link--text;

    cursor: pointer;
  }

  .page-navigation__link--active {
    font-weight: bold;
  }
</style>
