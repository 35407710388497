<script lang="ts" setup>
  import { PropType, reactive, ref } from "vue";
  import { useRoute } from "vue-router";

  import { weekBoundaries } from "shared/utils/date-utils";
  import { Calendar } from "frontend/interfaces/calendar";
  import { useStickyness } from "frontend/uses/use-stickyness";
  import { ElementProvider } from "frontend/utils/provider";
  import { useStore as useOfficeStore } from "frontend/stores/office";
  import { useStore as useProfileStore } from "frontend/stores/profile";
  import { AbstractCalendarOptions } from "frontend/uses/use-abstract-calendar";
  import { SettingsProfileContextName } from "frontend/utils/settings-profile-contexts";
  import { AppointmentListModal } from "frontend/utils/modals/appointment-list-modal";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseCalendar from "frontend/components/base/BaseCalendar.vue";
  import BaseBox from "frontend/components/base/BaseBox.vue";
  import MutatedSettingsProfile from "frontend/components/mutated/MutatedSettingsProfile.vue";

  defineProps({
    calendar: {
      type: Object as PropType<Calendar>,
      required: true,
    },
    displayDate: {
      type: String,
      default: null,
    },
    options: {
      type: Object as PropType<AbstractCalendarOptions>,
      required: true,
    },
    initialHighlight: {
      type: String,
      default: null,
    },
    highlightedDays: {
      type: Array as PropType<Array<Date>>,
      default: () => [],
    },
    contextName: {
      type: String as PropType<SettingsProfileContextName>,
      default: "calendar-day-view",
    },
  });

  const emit = defineEmits([
    "navigate:forward",
    "navigate:back",
    "navigate:current",
    "refresh-needed",
  ]);

  // ---------------------------------------------------------------------------
  // sticky stuff for sidebar and header
  // ---------------------------------------------------------------------------
  const headerElement = ElementProvider.localInjectSafe(
    ElementProvider.provisions().baseHeader
  );
  const { targetCSS: actionsStickyCSS } = useStickyness(
    headerElement,
    () => 10
  );
  const { targetCSS: sidebarStickyCSS } = useStickyness(
    headerElement,
    () => 50
  );
  const topbarElement = ref<HTMLElement | null>(null);
  const { targetCSS: topbarStickyCss, isStuck: topbarIsStuck } = useStickyness(
    headerElement,
    () => 0,
    topbarElement
  );
  const route = useRoute();
  const onAppointmentList = async () => {
    const date = route.params.day
      ? new Date(route.params.day.toString())
      : new Date();
    const data = reactive(
      route.name === "calendar-day"
        ? { start: date, end: date }
        : weekBoundaries(date)
    );
    await new AppointmentListModal().setData(data).show();
  };
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // navigate to current date and time
  // ---------------------------------------------------------------------------
  const doNavigateCurrent = async () => {
    emit("navigate:current");
    setTimeout(() => {
      const nowIndicator = document.getElementsByClassName("now-indicator");
      if (nowIndicator.length === 0) return;
      window.scrollTo({
        top:
          nowIndicator[0].getBoundingClientRect().top +
          window.scrollY -
          window.innerHeight / 2,
      });
    }, 0);
  };
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // Misc
  // ---------------------------------------------------------------------------
  const officeStore = useOfficeStore();
  const profileStore = useProfileStore();
  // ---------------------------------------------------------------------------
</script>

<template>
  <div class="vue-route__abstract-calendar">
    <div class="main-grid">
      <div
        ref="topbarElement"
        class="main-grid__topbar topbar"
        v-bind:class="{ 'topbar--stuck': topbarIsStuck }"
        v-bind:style="topbarStickyCss"
      >
        <div class="topbar__left">
          <BaseButton visual-inverted v-on:submit="doNavigateCurrent">
            {{ options.labelCurrent }}
          </BaseButton>
          <BaseButton class="topbar__time-navigator-button" visual-inverted>
            <i
              class="fe fe-arrow-left-boxed"
              v-on:click="$emit('navigate:back')"
            >
            </i>
            {{ displayDate }}
            <i
              class="fe fe-arrow-right-boxed"
              v-on:click="$emit('navigate:forward')"
            ></i>
          </BaseButton>
          <slot name="topbar"></slot>
        </div>
        <div class="calendar-view">
          <BaseButton v-on:click="onAppointmentList"> Liste </BaseButton>
          <BaseButton
            v-for="button in options.switchButtons"
            v-bind:key="button.key"
            v-bind:class="{ 'button--inverted': button.isActive.value }"
            v-on:submit="button.onSubmit()"
          >
            {{ button.label }}
          </BaseButton>
        </div>
      </div>
      <div class="main-grid__action" v-bind:style="actionsStickyCSS">
        <BaseButton
          visual-full-width
          visual-action
          v-bind:route="{ name: 'appointment-new' }"
          v-bind:disabled="officeStore.isBlocked"
        >
          Termin anlegen
        </BaseButton>
      </div>
      <div class="main-grid__calendar">
        <BaseCalendar
          v-if="calendar && calendar.rows[0].cells.length > 0"
          v-bind:calendar="calendar"
          v-bind:initial-highlight="initialHighlight"
          v-bind:visual-sticky-header-element="topbarElement"
          enable-drag-and-drop
          enable-resize
          enable-click
          note-sticky
          enable-now-indicator
          v-on:refresh-needed="$emit('refresh-needed')"
        />
        <p v-if="!calendar">
          Der Kalender beinhaltet noch keine Daten. Gehen Sie zunächst in die
          Einstellungen und legen Sie dort Ihre entsprechenden Mitarbeiter an,
          damit der Kalender erzeugt werden kann.
        </p>
        <p v-if="calendar.rows[0].cells.length === 0">
          Bitte wählen Sie mindestens eine Person in der Tagesansicht und einen
          Tag in der Wochenansicht des Kalenders aus, damit der Kalender erzeugt
          werden kann.
        </p>
      </div>
      <div class="main-grid__sidebar">
        <div v-bind:style="sidebarStickyCSS">
          <slot name="sidebar"></slot>

          <BaseBox heading="Einstellungen">
            <MutatedSettingsProfile
              v-if="profileStore.profile"
              v-bind:entity="profileStore.profile"
              v-bind:context-name="contextName"
              visual-small
            />
          </BaseBox>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .vue-route__abstract-calendar {
    // grid definition
    .main-grid {
      display: grid;
      /* prettier-ignore */
      grid-template:
        "topbar   action " auto
        "calendar sidebar" auto /
        auto      220px;
      gap: 22px;

      .main-grid__topbar {
        grid-area: topbar;
      }
      .main-grid__action {
        grid-area: action;
      }
      .main-grid__calendar {
        grid-area: calendar;
      }
      .main-grid__sidebar {
        grid-area: sidebar;
      }
    }

    .topbar {
      // positioning inside
      display: flex;
      justify-content: space-between;
      align-items: center;

      // for being stuck, see below
      box-sizing: border-box;
    }

    .topbar--stuck {
      background-color: colors.$color_main-area--background;
      z-index: 11;
      min-height: 50px;
    }

    .topbar__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      height: 31px;

      > div {
        margin-right: 22px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .topbar__time-navigator-button {
      // REFACTOR: overwritten button styles?
      padding-left: 7px;
      padding-right: 7px;

      .fe-arrow-left-boxed {
        // REFACTOR: dimension-variable?
        margin-right: 15px;
      }
      .fe-arrow-right-boxed {
        // REFACTOR: dimension-variable?
        margin-left: 15px;
      }
    }

    .calendar-view {
      margin-left: auto;
    }
  }
</style>
