<script lang="ts" setup>
  import { PropType, ref, reactive, watch, CSSProperties, computed } from "vue";
  import { differenceInMinutes, addMinutes } from "date-fns";

  import { PopupAppointmentEntry } from "frontend/interfaces/calendar";
  import { usePending } from "frontend/uses/use-pending";
  import { useTransience } from "frontend/uses/use-transience";
  import { Patient } from "frontend/interfaces/patient";
  import { AppointmentType } from "frontend/interfaces/appointment-type";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { useResponsiveness } from "frontend/uses/use-responsiveness";
  import {
    FormSelectId,
    useFormSelectOptions,
  } from "frontend/uses/use-form-select";
  import { TmpUnit } from "frontend/interfaces/unit";
  import { removeIfExists } from "shared/utils/array-utils";
  import { newClickUnit, unitFromSession } from "frontend/parser/parse-unit";

  import FormText from "frontend/components/form/FormText.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import PopupAppointmentPatientComponent from "frontend/components/PopupAppointmentPatient.vue";
  import ClickUnit from "frontend/components/ClickUnit.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  interface Decision {
    id: string;
    label: string;
  }
  const typeForPatient: Decision = {
    id: "1",
    label: "Für Patienten",
  };
  const typeOther: Decision = {
    id: "2",
    label: "Sonstiges",
  };
  const types: Array<Decision> = [typeForPatient, typeOther];

  const props = defineProps({
    entry: {
      type: Object as PropType<PopupAppointmentEntry>,
      required: true,
    },
    // can be used to preselect a patient
    patient: {
      type: Object as PropType<Patient | null>,
      default: null,
    },
  });
  const emit = defineEmits(["refresh-needed", "close-modal"]);

  const rootElement = ref<HTMLElement | null>(null);
  const rootStyle = reactive<{ maxHeight: CSSProperties["maxHeight"] }>({
    maxHeight: undefined,
  });
  useResponsiveness(() => {
    setTimeout(() => {
      if (!rootElement.value) return;
      const viewPortBottom =
        window.innerHeight ?? document.documentElement.clientHeight;
      const elementRect = rootElement.value.getBoundingClientRect();

      rootStyle.maxHeight =
        Math.max(viewPortBottom - elementRect.y, 100) + "px";
    }, 100);
  }, rootElement);

  const errorMessages = ref<Array<string>>([]);
  const errorUnitsPerson = ref<Array<PrimaryKey>>([]);
  const errorUnitsName = ref<Array<PrimaryKey>>([]);
  const tmpUnit = computed(() => props.entry.unit);
  const selectedFrom = computed(() => props.entry.unit.from);
  const selectedPersonId = computed(() => props.entry.unit.person_id);
  const notifyPatient = ref<boolean>(true);
  const remindPatient = ref<boolean>(true);

  //Type
  const { transientObject: selectedType } = useTransience(
    "popup-appointment__selected-type",
    props.entry.key,
    ref<Decision>(types[0])
  );

  //Patient
  const { transientObject: selectedPatient } = useTransience(
    "popup-appointment__selected-patient",
    props.entry.key,
    ref<Patient | null>(props.patient)
  );

  //Appointment type ID
  const { transientObject: selectedAppointmentTypeId } = useTransience(
    "popup-appointment__selected-appointment-type",
    props.entry.key,
    ref<FormSelectId | null>(null)
  );

  //Appointment types
  const { transientObject: appointmentTypes } = useTransience(
    "popup-appointment__selected-appointment-types",
    props.entry.key,
    ref<Array<AppointmentType>>([])
  );

  //Order Time in Minutes
  const { transientObject: orderTimeInMinutes } = useTransience(
    "popup-appointment__order_time",
    props.entry.key,
    ref<number>(0)
  );

  const { currentOption: selectedAppointmentType } = useFormSelectOptions(
    selectedAppointmentTypeId,
    appointmentTypes,
    (entry) => ({
      id: entry.id,
      label: entry.name,
    })
  );

  //Units
  const { transientObject: units } = useTransience(
    "popup-appointment__appointment__units",
    props.entry.key,
    ref<TmpUnit[]>([props.entry.unit])
  );

  watch(selectedAppointmentType, (newValue) => {
    if (newValue) {
      units.value = [];
      newValue.sessions.forEach((s, i) => {
        if (i === 0)
          units.value.push(
            unitFromSession(tmpUnit.value.from, tmpUnit.value.person_id, s)
          );
        else units.value.push(unitFromSession(units.value[i - 1].to, null, s));
      });
      onNewDate(units.value[0]);
    }
  });

  const { isPending, doWithPending } = usePending();
  const doSubmitAppointment = () => {
    errorMessages.value = [];
    errorUnitsPerson.value = [];
    errorUnitsName.value = [];
    doWithPending(async () => {
      let success = false;
      if (selectedType.value?.id === typeForPatient.id) {
        validatePatientAppointment();
        if (
          !selectedPatient.value ||
          !selectedAppointmentType.value ||
          errorUnitsPerson.value.length > 0 ||
          errorUnitsName.value.length > 0
        )
          return;

        success = await props.entry.doSubmit(
          units.value,
          selectedPatient.value,
          selectedAppointmentType.value,
          orderTimeInMinutes.value,
          notifyPatient.value,
          remindPatient.value
        );
      } else if (selectedType.value?.id === typeOther.id) {
        if (!tmpUnit.value.name) {
          errorMessages.value.push(
            "Sie müssen einen Namen für den Termin angeben"
          );
          return;
        }
        success = await props.entry.doSubmitUnit();
      } else {
        errorMessages.value.push("Unbekannte Terminart");
      }
      if (success) {
        emit("refresh-needed");
      } else {
        errorMessages.value.push("Konnte Termin nicht anlegen");
      }
    });
  };

  const onNewDate = (unit: TmpUnit) => {
    props.entry.onDateChange(unit.from, unit.to);
  };

  const onNewPersonID = (personId: PrimaryKey | null) => {
    if (personId) props.entry.onPersonChange(personId);
  };

  const doUpdateIntervals = (index = 0) => {
    units.value.forEach((unit, i) => {
      if (i > index) {
        const amount = differenceInMinutes(unit.to, unit.from);
        unit.from = units.value[i - 1].to;
        unit.to = addMinutes(unit.from, amount);
      }
    });
  };

  watch([selectedFrom, selectedPersonId], () => {
    units.value[0].from = tmpUnit.value.from;
    units.value[0].to = tmpUnit.value.to;
    units.value[0].person_id = tmpUnit.value.person_id;
    doUpdateIntervals();
  });

  const doAddUnit = () => {
    const newFrom = units.value[units.value.length - 1].to;
    units.value.push(newClickUnit(null, newFrom));
  };

  const doDeleteUnit = (unit: TmpUnit, index: number) => {
    if (index === 0 && !units.value[1].person_id)
      units.value[1].person_id = unit.person_id;

    removeIfExists(units.value, unit);
    doUpdateIntervals(index);

    if (index === 0) {
      onNewDate(units.value[0]);
      onNewPersonID(units.value[0].person_id);
    }
  };

  const validatePatientAppointment = () => {
    if (!selectedPatient.value) {
      errorMessages.value.push("Sie müssen einen Patienten auswählen");
    }
    if (!selectedAppointmentType.value) {
      errorMessages.value.push("Sie müssen einen Vorstellungsanlass auswählen");
    }
    units.value.forEach((unit) => {
      if (!unit.person_id) errorUnitsPerson.value.push(unit.key);
      if (!unit.name) errorUnitsName.value.push(unit.key);
    });
  };
</script>

<template>
  <div
    ref="rootElement"
    class="popup-appointment"
    v-bind:style="rootStyle"
    v-on:click.stop
  >
    <h5>Neuen Termin erstellen</h5>

    <!-- decision -->
    <FormText
      v-if="!patient"
      class="popup-appointment__labeling"
      label="Terminart"
    >
      <div class="popup-appointment__decisions">
        <div
          v-for="type in types"
          v-bind:key="type.id"
          class="popup-appointment__decision"
          v-bind:class="{
            'popup-appointment__decision--active':
              selectedType && selectedType.id === type.id,
          }"
          v-on:click.stop="
            selectedType = type;
            errorMessages = [];
          "
        >
          {{ type.label }}
        </div>
      </div>
    </FormText>
    <template v-if="selectedType.id === typeForPatient.id">
      <PopupAppointmentPatientComponent
        v-model:selected-patient="selectedPatient"
        v-model:selected-appointment-type-id="selectedAppointmentTypeId"
        v-model:appointment-types="appointmentTypes"
        v-model:order-time-in-minutes="orderTimeInMinutes"
        v-bind:selected-from="selectedFrom"
        v-bind:selected-person-id="selectedPersonId"
        v-bind:hide-patient-selection="!!patient"
      />
      <template v-if="selectedAppointmentType">
        <div
          v-bind:class="{
            'popup-appointment__row-units': units.length === 2,
            'popup-appointment__column-units': units.length > 2,
          }"
        >
          <div
            v-for="(unit, index) in units"
            v-bind:key="unit.key"
            v-bind:class="{
              'popup-appointment__row-unit': units.length === 2,
              'popup-appointment__column-unit': units.length > 2,
            }"
          >
            <ClickUnit
              v-model:unit="units[index]"
              v-bind:index="index"
              v-bind:disable-limit-on-from="!!patient && index === 0"
              v-on:new-date="(unit) => onNewDate(unit)"
              v-on:new-person="(personId) => onNewPersonID(personId)"
              v-on:update-intervals="(index) => doUpdateIntervals(index)"
            />
            <span
              v-if="units.length > 1"
              class="popup-appointment__unit__deletion-link"
              v-on:click="doDeleteUnit(unit, index)"
              >Einzeltermin löschen</span
            >
            <div
              v-if="errorUnitsPerson.includes(unit.key)"
              class="popup-appointment__error"
            >
              Sie müssen einen Mitarbeiter auswählen
            </div>
            <div
              v-if="errorUnitsName.includes(unit.key)"
              class="popup-appointment__error"
            >
              Sie müssen einen Namen für den Termin angeben
            </div>
          </div>
        </div>
        <div class="popup-appointment__unit__add" v-on:click="doAddUnit()">
          <div v-if="units.length === 1">In Kettentermin umwandeln</div>
          <div v-else>Weiteren Einzeltermin hinzufügen</div>
        </div>
      </template>
    </template>
    <template v-else>
      <ClickUnit
        v-model:unit="tmpUnit"
        v-bind:index="0"
        v-on:new-date="(unit) => onNewDate(unit)"
        v-on:new-person="(personId) => onNewPersonID(personId)"
        v-on:update-intervals="(index) => doUpdateIntervals(index)"
      />
    </template>
    <!-- errors -->
    <div class="popup-appointment__errors">
      <div
        v-for="error in errorMessages"
        v-bind:key="error"
        class="popup-appointment__error"
      >
        {{ error }}
      </div>
    </div>
    <FormCheckbox
      v-if="selectedAppointmentTypeId && selectedType.id === typeForPatient.id"
      v-model="notifyPatient"
      label="Patienten über Terminbuchung informieren"
    />
    <FormCheckbox
      v-if="selectedAppointmentTypeId && selectedType.id === typeForPatient.id"
      v-model="remindPatient"
      label="Patienten Terminerinnerungen senden"
    />
    <!-- buttons -->
    <div
      class="popup-appointment__actions"
      v-bind:class="{
        'popup-appointment__modal-actions': !!patient,
      }"
    >
      <BaseButton
        class="popup-appointment__action"
        v-on:submit="
          entry.doDestroy();
          $emit('close-modal');
        "
      >
        abbrechen
      </BaseButton>
      <BaseButton
        class="popup-appointment__action"
        visual-action
        v-bind:disabled="isPending"
        v-on:submit="doSubmitAppointment"
      >
        <template v-if="isPending"><BaseSpinner /></template>
        <template v-else>Termin anlegen</template>
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/link";
  @use "frontend/styles/mixins/tiles";
  @use "frontend/styles/mixins/label";

  .popup-appointment {
    background-color: colors.$color_popup--background;
    padding: 20px;

    h5 {
      margin-top: 0;
    }
  }

  .popup-appointment__errors {
    margin-top: 25px;
  }

  .popup-appointment__error {
    @include label.label;
    @include label.label--error;
  }

  .popup-appointment__actions {
    display: flex;
    justify-content: center;

    margin-top: 30px;
  }

  .popup-appointment__action {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .popup-appointment__labeling {
    margin: 25px 0;
  }

  .popup-appointment__decisions {
    @include tiles.tile__collection;
    justify-content: center;

    margin: 5px 0;
  }

  .popup-appointment__decision {
    @include tiles.tile;

    padding: 7px 15px;
    border-radius: 3px;

    font-weight: bold;
    font-size: 12px;

    &:hover {
      @include tiles.tile--active;
    }
  }

  .popup-appointment__decision--active {
    @include tiles.tile--active;
  }

  .popup-appointment__row-units {
    display: flex;
    flex-direction: row;
  }

  .popup-appointment__column-units {
    display: flex;
    flex-direction: column;
  }

  .popup-appointment__row-unit {
    max-width: 220px;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;

    border-left: 1px solid colors.$color_settings-sessions--border;

    &:first-of-type {
      border-left: none;
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .popup-appointment__column-unit {
    margin-top: 30px;
  }

  .popup-appointment__unit__deletion-link {
    color: colors.$color_link--text;
    font-size: 12px;
    cursor: pointer;
    text-align: end;
  }

  .popup-appointment__unit__add {
    @include link.link;

    margin-top: 10px;
    font-size: 12px;
    text-align: end;
  }

  .popup-appointment__modal-actions {
    padding-bottom: 20px;
  }
</style>
