<script lang="ts">
  import { computed, defineComponent, PropType, ref, toRefs, unref } from "vue";

  import { useColorPicker } from "frontend/uses/use-color-picker";
  import { DEFAULT_COLORS } from "shared/static/default-colors.ts.erb";
  import { StatusValue } from "frontend/utils/animation-status";
  import { Errors } from "frontend/uses/use-errors";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { AnimationStatus, BaseButton },
    props: {
      modelValue: {
        type: String,
        default: null,
      },
      errors: {
        type: Array as PropType<Errors>,
        default: () => [],
      },
      animationStatus: {
        type: Number as PropType<StatusValue | null>,
        default: null,
      },
    },
    emits: ["update:modelValue", "autosave"],
    setup(props, { emit }) {
      const { modelValue } = toRefs(props);
      const sneakPeekColor = ref<string | null>(null);
      const pickerIsOpen = ref<boolean>(false);
      const updateSneakPeek = (newValue: string, updatePicker = false) => {
        sneakPeekColor.value = newValue;
        if (updatePicker && setColor) setColor(newValue);
      };
      const {
        container,
        doOpenPicker: doOpenPickerRaw,
        pickerIsOpen: pickerIsOpenRaw,
        setColor,
        calculatContrastColorCSS,
      } = useColorPicker(updateSneakPeek);
      const doOpenPicker = () => {
        if (pickerIsOpen.value) return;
        pickerIsOpen.value = true;
        if (!pickerIsOpenRaw.value) doOpenPickerRaw(modelValue.value);
        updateSneakPeek(modelValue.value);
      };
      const previewStyling = computed(() => ({
        "background-color": unref(modelValue),
      }));
      const sneakPeekStyling = computed(() => ({
        "background-color":
          sneakPeekColor.value === null ? undefined : sneakPeekColor.value,
        color: calculatContrastColorCSS(unref(sneakPeekColor)),
      }));
      const doCommitSneakPeek = (persist: boolean) => {
        pickerIsOpen.value = false;
        if (persist) {
          emit(
            "update:modelValue",
            sneakPeekColor.value
              ? sneakPeekColor.value.toUpperCase()
              : sneakPeekColor.value
          );
          emit("autosave");
        }
      };
      return {
        previewStyling,
        sneakPeekStyling,
        container,
        doOpenPicker,
        pickerIsOpen,
        sneakPeekColor,
        updateSneakPeek,
        doCommitSneakPeek,
        DEFAULT_COLORS,
      };
    },
  });
</script>

<template>
  <div>
    <div class="form-color">
      <div
        class="form-color__preview"
        v-bind:style="previewStyling"
        v-on:click="doOpenPicker"
      ></div>
      <AnimationStatus
        v-if="animationStatus !== null"
        class="form-input__animation-status"
        v-bind:animation-status="animationStatus"
      />
      <div
        v-show="pickerIsOpen"
        ref="container"
        class="form-color__container"
      ></div>
      <div v-if="pickerIsOpen" class="form-color__swatches">
        <div
          v-for="color in DEFAULT_COLORS"
          v-bind:key="color.name"
          class="form-color__swatch"
          v-bind:style="{ 'background-color': color.value }"
          v-on:click="updateSneakPeek(color.value, true)"
        ></div>
      </div>
      <div v-if="pickerIsOpen" class="form-color__actions">
        <div
          class="form-color__action form-color__sneak-peak"
          v-bind:style="sneakPeekStyling"
        >
          {{ sneakPeekColor }}
        </div>
        <div class="form-color__action"><strong>Farbe</strong></div>
        <BaseButton
          class="form-color__action"
          visual-full-width
          v-on:submit="doCommitSneakPeek(true)"
          >Übernehmen</BaseButton
        >
        <BaseButton
          class="form-color__action"
          visual-full-width
          v-on:submit="doCommitSneakPeek(false)"
          >Abbrechen</BaseButton
        >
      </div>
      <input type="hidden" v-bind:value="modelValue" />
    </div>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="form-color__label__error"
        >{{ error }}</label
      ></template
    >
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  .form-color {
    display: flex;
    align-items: flex-end;

    > div {
      margin-right: 20px;
    }
  }

  .form-color__preview {
    width: 30px;
    height: 30px;

    cursor: pointer;

    border: 1px solid colors.$color_form-color__preview--border;
  }

  .form-color__action {
    font-size: 10px;
  }

  .form-color__actions {
    display: flex;
    flex-direction: column;

    > .form-color__action {
      margin-bottom: 5px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .form-color__swatches {
    // height: 50px;
    width: 50px;
    display: flex;
    flex-flow: row wrap;

    margin-bottom: 10px;
  }

  .form-color__swatch {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: 5px;

    cursor: pointer;
  }

  .form-color__sneak-peak {
    margin-bottom: 10px !important;

    text-align: center;
    padding: 5px;
    font-weight: bold;
  }

  .form-color__label__error {
    @include label.label;
    @include label.label--error;
  }
</style>
