<script lang="ts" setup>
  import { computed } from "vue";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { Appointment } from "frontend/interfaces/p/appointment";

  import BaseBox from "frontend/components/base/BaseBox.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  interface LocalAppointment {
    id: PrimaryKey;
    name: string;
    begin: Date;
    status: number;
  }

  type PossibleAppointment = LocalAppointment | Appointment;

  const localAppointmentDiscriminator = (
    element: PossibleAppointment
  ): element is LocalAppointment => !(element as Appointment)._policy;
  const appointmentDiscriminator = (
    element: PossibleAppointment
  ): element is Appointment => !localAppointmentDiscriminator(element);

  const props = withDefaults(
    defineProps<{
      appointments?: PossibleAppointment[];
      title?: string | null;
      note?: string | null;
    }>(),
    { appointments: () => [], title: null, note: null }
  );

  defineEmits<{
    (e: "deleteAppointment", appointment: Appointment): void;
  }>();

  const theLocalAppointments = computed<LocalAppointment[]>(() =>
    props.appointments.filter(localAppointmentDiscriminator)
  );
  const theAppointments = computed<Appointment[]>(() =>
    props.appointments.filter(appointmentDiscriminator)
  );
</script>

<template>
  <div>
    <h5>{{ title }}</h5>
    <div v-if="appointments.length > 0">
      <div v-if="theLocalAppointments.length > 0">
        <div class="appointments__wrapper appointments--header">
          <div>Termintyp</div>
          <div>Datum</div>
          <div>Uhrzeit</div>
          <div>Link</div>
        </div>
        <div
          v-for="appointment in theLocalAppointments"
          v-bind:key="appointment.id"
          class="appointments__wrapper appointments__info"
        >
          <div>{{ appointment.name }}</div>
          <div>{{ formatDate(appointment.begin, DateFormat.DateOnly) }}</div>
          <div>
            {{
              formatDate(appointment.begin, DateFormat.TimeOnlyWithoutSeconds)
            }}
          </div>
          <div>
            <RouterLink
              v-bind:to="{
                name: 'calendar-day',
                params: {
                  day: formatDate(appointment.begin, DateFormat.DateOnlyISO),
                },
                query: {
                  highlightAppointmentId: appointment.id,
                },
              }"
              class=""
            >
              <i title="im Kalender anzeigen" class="fe fe-calendar-day"></i>
            </RouterLink>
          </div>
        </div>
      </div>

      <div v-if="theAppointments.length > 0">
        <BaseBox
          v-for="appointment in theAppointments"
          v-bind:key="appointment.id"
          class="appointment-box"
          visual-no-heading
        >
          <div class="appointment-box__container">
            <i class="fe fe-calendar-day appointment-icon__calendar"></i>
            <div>
              <div class="appointment__date">
                <strong>
                  {{ formatDate(appointment.begin, DateFormat.DateOnlyLong) }}
                  um
                  {{
                    formatDate(
                      appointment.begin,
                      DateFormat.TimeOnlyWithoutSeconds
                    )
                  }}
                </strong>
              </div>
              <div>Besuchsgrund: {{ appointment.appointment_type_name }}</div>
            </div>
            <BaseButton
              v-if="appointment._policy.destroy"
              class="appointment-button__delete"
              visual-icon
              v-on:submit="$emit('deleteAppointment', appointment)"
              ><i class="fe fe-trash"
            /></BaseButton>
          </div>
        </BaseBox>
      </div>
    </div>
    <div v-else>{{ note }}</div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .appointments__wrapper {
    display: flex;
    justify-content: space-between;

    padding: 8px;
    border: 1px solid colors.$color_base-list--separator;
  }

  .appointments--header {
    background-color: colors.$color_patient-search__patient--background;

    > div {
      flex: 1;
    }
  }

  .appointments__info div {
    flex: 1;
  }

  .appointment-box {
    margin: 10px 0;
  }

  .appointment-box__container {
    display: flex;
  }

  .appointment-icon__calendar {
    font-size: 20px;
    padding-right: 10px;
  }

  .appointment__date {
    margin-bottom: 5px;
  }

  .appointment-button__delete {
    background-color: transparent;
    color: colors.$color_main-area--background;
    font-size: 20px;
    padding: 0;
    margin-left: auto;
    align-self: center;

    &:hover {
      background-color: transparent;
      font-size: 30px;
    }
  }
</style>
