<script lang="ts">
  import { computed, defineComponent, ref } from "vue";

  import { WizardStep } from "frontend/interfaces/wizard";

  import BaseBlindtext from "frontend/components/base/BaseBlindtext.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseBox from "frontend/components/base/BaseBox.vue";
  import WizardSteps from "frontend/components/WizardSteps.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import FormRelativeTime from "frontend/components/form/FormRelativeTime.vue";

  export default defineComponent({
    components: {
      BaseBlindtext,
      BaseButton,
      BaseBox,
      WizardSteps,
      FormInput,
      FormSelect,
      FormDatetime,
      FormCheckbox,
      FormRelativeTime,
    },
    setup() {
      const steps: Array<WizardStep> = [
        { label: "Schritt 1", slotName: "step1" },
        { label: "Schritt 2", slotName: "step2" },
        { label: "Schritt 3", slotName: "step3" },
        { label: "Schritt 4", slotName: "step4" },
        { label: "Schritt 5", slotName: "step5" },
      ];

      const date = ref<Date>(new Date());
      const showErrors = ref<boolean>(false);
      const disable = ref<boolean>(false);
      const errors = computed(() => {
        if (!showErrors.value) return [];
        else return ["muss ausgefüllt werden"];
      });
      return {
        steps,
        date,
        showErrors,
        disable,
        errors,
      };
    },
  });
</script>

<template>
  <div class="vue-component__style-guide">
    <div class="plain">
      <h1>Styleguide</h1>

      This is a styleguide that presents the current CSS of the project and
      shows how standard elements are styled.

      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>

      <h2>Paragraphs</h2>
      <BaseBlindtext />

      <h3>Without paragraph</h3>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
      ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
      dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
      nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
      Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In
      enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum
      felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus
      elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo
      ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem
      ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla
      ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies
      nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam
      rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper
      libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit
      vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante
      tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam
      quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed
      fringilla mauris sit amet nibh. Donec sodales sagittis magna.

      <h2>Hightlighting</h2>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
      <mark>Aenean commodo ligula eget dolor.</mark>
      <span style="color: #ff0000">&nbsp;(mark)</span> Aenean massa. Cum sociis
      natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
      <em
        >Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
        sem.</em
      >
      <span style="color: #ff0000">&nbsp;(em)</span> Nulla consequat massa quis
      enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
      In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
      <strong>Nullam dictum felis eu pede mollis pretium.</strong
      ><span style="color: #ff0000">&nbsp;(strong)</span> Integer tincidunt.
      Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend
      tellus.
      <a href="#"
        >Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.</a
      ><span style="color: #ff0000">&nbsp;(a)</span> Aliquam lorem ante, dapibus
      in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus
      varius laoreet. Quisque rutrum.
      <i>Aenean imperdiet. Etiam ultricies nisi vel augue.</i>
      <span style="color: #ff0000">&nbsp;(i)</span> Curabitur ullamcorper
      ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget
      condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque
      sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id,
      lorem. Maecenas nec odio et ante tincidunt tempus.
      <b>Donec vitae sapien ut libero venenatis faucibus.</b>
      <span style="color: #ff0000">&nbsp;(b)</span> Nullam quis ante.
      <u>Etiam sit amet orci eget eros faucibus tincidunt.</u>
      <span style="color: #ff0000">&nbsp;(u)</span> Duis leo. Sed fringilla
      mauris sit amet nibh. Donec sodales sagittis magna.

      <h2>Lists</h2>
      <h3>Unordered List</h3>
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</li>
        <li>Aenean commodo ligula eget dolor</li>
        <li>Aenean massa</li>
        <li>
          Cum sociis natoque penatibus et magnis dis parturient montes, nascetur
          ridiculus mus
        </li>
        <li>
          Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem
        </li>
        <li>Nulla consequat massa quis enim</li>
        <li>
          Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu
        </li>
        <li>In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo</li>
        <li>Nullam dictum felis eu pede mollis pretium</li>
        <li>Integer tincidunt</li>
        <li>Cras dapibus</li>
      </ul>

      We can have a horizontal rule too.

      <hr />

      <h3>Ordered List</h3>
      <ol>
        <li>Vivamus elementum semper nisi</li>
        <li>Aenean vulputate eleifend tellus</li>
        <li>
          Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim
        </li>
        <li>Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus</li>
        <li>Phasellus viverra nulla ut metus varius laoreet</li>
        <li>Quisque rutrum</li>
        <li>Aenean imperdiet</li>
        <li>Etiam ultricies nisi vel augue</li>
        <li>Curabitur ullamcorper ultricies nisi</li>
        <li>Nam eget dui</li>
        <li>Etiam rhoncus</li>
      </ol>

      <h2 id="buttons">Buttons</h2>
      <div>
        <pre>BaseButton</pre>
        <div>
          <BaseButton>Elit aliqua duis occaecat ipsum</BaseButton>&nbsp;
          <BaseButton>Elit aliqua duis occaecat ipsum</BaseButton>&nbsp;
          <BaseButton>Elit aliqua duis occaecat ipsum</BaseButton>
        </div>
      </div>
      <div>
        <pre>BaseButton (with icon)</pre>
        <div>
          <BaseButton><i class="fe fe-cog"></i></BaseButton>
        </div>
        <br />
        <div>
          <BaseButton>
            <i class="fe fe-cog button__icon"> </i>Elit aliqua duis occaecat
            ipsum
          </BaseButton>
        </div>
        <br />
        <div>
          <BaseButton>
            <i class="fe fe-magnifier button__icon"> </i>Elit aliqua duis
            occaecat ipsum
          </BaseButton>
        </div>
      </div>
      <div>
        <pre>BaseButton visual-small</pre>
        <div>
          <BaseButton visual-small>Elit aliqua duis occaecat ipsum</BaseButton>
        </div>
      </div>
      <div>
        <pre>BaseButton visual-action</pre>
        <div>
          <BaseButton visual-action>Elit aliqua duis occaecat ipsum</BaseButton>
        </div>
      </div>
      <div>
        <pre>BaseButton visual-warn</pre>
        <div>
          <BaseButton visual-warn>Elit aliqua duis occaecat ipsum</BaseButton>
        </div>
      </div>
      <div>
        <pre>BaseButton visual-full-width</pre>
        <div>
          <BaseButton visual-full-width>
            Elit aliqua duis occaecat ipsum
          </BaseButton>
          <br />
          <BaseButton visual-full-width>
            <i class="fe fe-cog button__icon"></i>Elit aliqua duis occaecat
            ipsum
          </BaseButton>
        </div>
      </div>
    </div>
    <div>
      <pre>BaseButton visual-inverted</pre>
      <div>
        <BaseButton visual-inverted>
          Elit aliqua duis occaecat ipsum
        </BaseButton>
        &nbsp;
        <BaseButton visual-inverted>
          <i class="fe fe-cog button__icon"></i>Elit aliqua duis occaecat ipsum
        </BaseButton>
        &nbsp;
        <BaseButton visual-inverted visual-action>
          <i class="fe fe-cog button__icon"></i>Elit aliqua duis occaecat ipsum
        </BaseButton>
        &nbsp;
        <BaseButton visual-inverted visual-warn>
          <i class="fe fe-cog button__icon"></i>Elit aliqua duis occaecat ipsum
        </BaseButton>
      </div>
      <h2 id="buttons">Boxes</h2>
      <div>
        <pre>.box</pre>
        <BaseBox heading="Heading">
          Consectetur aute nostrud anim aute non tempor cillum dolore velit
          ullamco non. Cupidatat nisi laborum aliquip reprehenderit dolor magna
          eu duis consectetur quis. Anim magna enim et in esse cillum est. Nisi
          culpa officia amet reprehenderit. Ipsum ad reprehenderit esse
          exercitation aliqua enim adipisicing nostrud eiusmod mollit commodo id
          laboris pariatur. Ea pariatur reprehenderit irure labore enim et ad.
        </BaseBox>
        <pre>.box (with custom heading)</pre>
        <BaseBox heading="Heading">
          <template v-slot:heading
            >My <i>custom</i> <strong>heading</strong></template
          >
          Consectetur aute nostrud anim aute non tempor cillum dolore velit
          ullamco non. Cupidatat nisi laborum aliquip reprehenderit dolor magna
          eu duis consectetur quis. Anim magna enim et in esse cillum est. Nisi
          culpa officia amet reprehenderit. Ipsum ad reprehenderit esse
          exercitation aliqua enim adipisicing nostrud eiusmod mollit commodo id
          laboris pariatur. Ea pariatur reprehenderit irure labore enim et ad.
        </BaseBox>
      </div>
      <div style="max-width: 275px">
        <pre>.box (in narrower container)</pre>
        <BaseBox heading="Heading">
          Consectetur aute nostrud anim aute non tempor cillum dolore velit
          ullamco non. Cupidatat nisi laborum aliquip reprehenderit dolor magna
          eu duis consectetur quis. Anim magna enim et in esse cillum est. Nisi
          culpa officia amet reprehenderit. Ipsum ad reprehenderit esse
          exercitation aliqua enim adipisicing nostrud eiusmod mollit commodo id
          laboris pariatur. Ea pariatur reprehenderit irure labore enim et ad.
        </BaseBox>
        <pre>.box (with custom heading)</pre>
        <BaseBox heading="Heading">
          <template v-slot:heading
            >My <i>custom</i> <strong>heading</strong></template
          >
          Consectetur aute nostrud anim aute non tempor cillum dolore velit
          ullamco non. Cupidatat nisi laborum aliquip reprehenderit dolor magna
          eu duis consectetur quis. Anim magna enim et in esse cillum est. Nisi
          culpa officia amet reprehenderit. Ipsum ad reprehenderit esse
          exercitation aliqua enim adipisicing nostrud eiusmod mollit commodo id
          laboris pariatur. Ea pariatur reprehenderit irure labore enim et ad.
        </BaseBox>
      </div>
    </div>

    <h2 id="wizard">Wizard</h2>
    <div class="plain">
      <WizardSteps v-bind:steps="steps">
        <template v-slot:step1>
          <h3>Schritt 1</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step2>
          <h3>Schritt 2</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step3>
          <h3>Schritt 3</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step4>
          <h3>Schritt 4</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step5>
          <h3>Schritt 5</h3>
          <BaseBlindtext />
        </template>
      </WizardSteps>
    </div>

    <div class="backgrounded">
      <WizardSteps v-bind:steps="steps" visual-inverted>
        <template v-slot:step1>
          <h3>Schritt 1</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step2>
          <h3>Schritt 2</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step3>
          <h3>Schritt 3</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step4>
          <h3>Schritt 4</h3>
          <BaseBlindtext />
        </template>
        <template v-slot:step5>
          <h3>Schritt 5</h3>
          <BaseBlindtext />
        </template>
      </WizardSteps>
    </div>

    <div class="plain">
      <h2>Formulare</h2>
      <FormInput
        v-bind:disabled="disable"
        v-bind:errors="errors"
        label="Testinput"
        placeholder="mit Placeholder"
      />
      <FormInput
        v-bind:disabled="disable"
        label="Testtextarea"
        type="textarea"
        placeholder="mit Placeholder"
        v-bind:errors="errors"
      />
      <FormDatetime
        v-model="date"
        v-bind:disabled="disable"
        label="Test DateTime"
        visual-small
        v-bind:errors="errors"
      />
      <FormDatetime
        v-model="date"
        v-bind:disabled="disable"
        label="Test DateTime mit Uhrzeit"
        enable-time
        visual-small
        v-bind:errors="errors"
      />
      <FormSelect
        v-bind:disabled="disable"
        label="Testselect"
        v-bind:options="[{ id: '1', label: 'Testeintrag' }]"
        v-bind:errors="errors"
      />
      <FormRelativeTime
        label="Zeitabstand"
        v-bind:disabled="disable"
        v-bind:errors="errors"
      />
      <FormCheckbox
        v-model="showErrors"
        label="Fehler anzeigen"
        v-bind:disabled="disable"
      />
      <FormCheckbox v-model="disable" label="Deaktivieren" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .vue-component__style-guide {
    .backgrounded {
      background-color: colors.$color_main-area--background;
      color: colors.$color_main-area--text;

      padding: 20px;
      margin: 20px 0;
    }
  }
</style>
