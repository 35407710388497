<script lang="ts">
  import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    PropType,
    ref,
    unref,
    watch,
  } from "vue";
  import {
    addDays,
    compareDesc,
    differenceInSeconds,
    isAfter,
    isSameDay,
    parseISO,
  } from "date-fns";

  import { Patient } from "frontend/interfaces/patient";
  import { useAppointmentTypes } from "frontend/uses/p/use-appointment-types";
  import {
    FormSelectId,
    useFormSelectOptions,
  } from "frontend/uses/use-form-select";
  import { AppointmentType } from "frontend/interfaces/p/appointment-type";
  import {
    discardSuggestions,
    requestSuggestions,
  } from "frontend/api/application/p/request-suggestions";
  import { usePending } from "frontend/uses/use-pending";
  import { Suggestion } from "frontend/interfaces/p/suggestion";
  import { parseSuggestion } from "frontend/parser/p/parse-suggestion";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { usePillarCalendar } from "frontend/uses/use-pillar-calendar";
  import { requestPersons } from "frontend/api/application/p/request-persons";
  import { Person } from "frontend/interfaces/p/person";
  import { parsePerson } from "frontend/parser/p/parse-person";
  import {
    DateFormat,
    formatDate,
    isBetween,
    today,
  } from "shared/utils/date-utils";
  import { useRecurring } from "frontend/uses/use-recurring";

  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import PillarCalendar from "frontend/components/PillarCalendar.vue";

  export default defineComponent({
    components: {
      FormSelect,
      FormDatetime,
      BaseButton,
      BaseSpinner,
      PillarCalendar,
    },
    props: {
      patient: {
        type: Object as PropType<Patient>,
        required: true,
      },
    },
    emits: ["selection"],
    setup(_, { emit }) {
      // -------------------------------------------------------
      // Suggestions
      const suggestions = ref<Array<Suggestion>>([]);
      const suggestionsDaysSearched = ref<Array<Date>>([]);
      const suggestionsLoaded = ref<boolean>(false);
      const suggestionsReservedUntil = ref<Date | null>(null);
      const searchRef = ref<PrimaryKey | null>(null);
      const {
        isPending: pendingSuggestions,
        doWithPending: doPendingSuggestions,
      } = usePending();
      const resetSuggestions = () => {
        suggestions.value = [];
        suggestionsDaysSearched.value = [];
        suggestionsLoaded.value = false;
        suggestionsReservedUntil.value = null;
        const searchRefU = unref(searchRef);
        if (searchRefU != null) {
          searchRef.value = null;
          discardSuggestions(searchRefU);
        }
      };
      const createSuggestions = async (options?: { search_from?: Date }) => {
        await doPendingSuggestions(async () => {
          const appointmentType = unref(currentOptionAT);
          if (!appointmentType) return;

          const data = await requestSuggestions(
            appointmentType.id,
            options?.search_from ?? searchFrom.value ?? today(),
            {
              person_id: currentSelectionP.value as string | undefined,
              search_ref: searchRef.value,
            }
          );

          suggestions.value = data.suggestions.map(parseSuggestion);
          suggestionsDaysSearched.value = data.days_searched.map((x) =>
            parseISO(x)
          );
          searchRef.value = data.search_ref;
          suggestionsLoaded.value = true;
          suggestionsReservedUntil.value = data.reserved_until
            ? parseISO(data.reserved_until)
            : null;
        });
      };
      // -------------------------------------------------------

      // -------------------------------------------------------
      // AppointmentTypes
      const { appointmentTypes, reloadAppointmentTypes } =
        useAppointmentTypes();
      onMounted(reloadAppointmentTypes);
      const currentSelectionAT = ref<FormSelectId>(null);
      const { options: optionsAT, currentOption: currentOptionAT } =
        useFormSelectOptions<AppointmentType>(
          currentSelectionAT,
          appointmentTypes,
          (entry) => ({ id: entry.id, label: entry.name })
        );
      // -------------------------------------------------------

      // -------------------------------------------------------
      // Start search date
      const searchFrom = ref<Date | null>(null);
      watch(
        currentOptionAT,
        (newValue) => {
          if (!newValue) searchFrom.value = null;
          else if (
            !searchFrom.value ||
            !isBetween(
              searchFrom.value,
              newValue.allowed_booking_range_from,
              newValue.allowed_booking_range_to
            )
          )
            searchFrom.value = newValue.allowed_booking_range_from;
        },
        { immediate: true }
      );
      const maxDaySearched = computed<Date | null>(
        () => [...suggestionsDaysSearched.value].sort(compareDesc)[0] ?? null
      );
      const nextDayToSearch = computed<Date | null>(() =>
        maxDaySearched.value ? addDays(maxDaySearched.value, 1) : null
      );
      const additionalLabelForPillar = computed<string | null>(() => {
        if (pendingSuggestions.value) return null;

        const searchFromU = searchFrom.value;
        const maxDaySearchedU = maxDaySearched.value;
        const nextDayToSearchU = nextDayToSearch.value;
        const currentOptionATU = currentOptionAT.value;

        if (
          !searchFromU ||
          !maxDaySearchedU ||
          !currentOptionATU ||
          !nextDayToSearchU
        )
          return null;

        if (isAfter(searchFromU, maxDaySearchedU)) return null;
        if (
          isSameDay(maxDaySearchedU, currentOptionATU.allowed_booking_range_to)
        )
          return null;

        return `Weitere Termine ab ${formatDate(
          nextDayToSearchU,
          DateFormat.DateOnly
        )} suchen`;
      });
      const loadNextSuggestions = async (navigatePillars: () => void) => {
        if (!nextDayToSearch.value) return;

        await createSuggestions({ search_from: nextDayToSearch.value });
        await nextTick();
        navigatePillars();
      };
      // -------------------------------------------------------

      // -------------------------------------------------------
      // Persons
      const persons = ref<Person[]>([]);
      const hasPersonSelector = computed(() => {
        return persons.value && persons.value.length > 0;
      });
      const reloadPersons = async () => {
        const appointment_type_id = (
          currentSelectionAT.value ? currentSelectionAT.value : undefined
        ) as string | undefined;
        const result = await requestPersons(appointment_type_id);
        if (result.success) {
          persons.value = result.entities.map(parsePerson);
        }
      };
      watch(currentSelectionAT, async () => {
        currentSelectionP.value = null;
        persons.value = [];
        await reloadPersons();
      });
      const currentSelectionP = ref<FormSelectId>(null);
      const { options: optionsP } = useFormSelectOptions<Person>(
        currentSelectionP,
        persons,
        (p) => ({ id: p.id, label: p.shown_name })
      );
      // -------------------------------------------------------

      const { pillarCalendar } = usePillarCalendar<Suggestion>(
        suggestions,
        (suggestion) => ({
          id: suggestion.appointment_id,
          time: suggestion.begin,
        }),
        async (suggestion) => {
          emit("selection", unref(currentOptionAT), unref(suggestion));
        }
      );

      watch([currentSelectionP, currentOptionAT], () => {
        resetSuggestions();
      });

      // -------------------------------------------------------
      // Reservation
      const { tick } = useRecurring(1000);
      const reservedForSeconds = computed<number | null>(() => {
        if (!suggestionsReservedUntil.value) return null;

        unref(tick);

        return Math.max(
          0,
          differenceInSeconds(suggestionsReservedUntil.value, new Date())
        );
      });
      const reservedFormatted = computed<string | null>(() => {
        const seconds = reservedForSeconds.value;
        if (!seconds || seconds <= 0) return null;

        const minutes = Math.floor(seconds / 60);
        const remainder = Math.max(0, seconds - minutes * 60);

        let result = "";
        if (minutes > 0) {
          result += minutes.toString();
          if (minutes === 1) result += " Minute";
          else result += " Minuten";
          result += " ";
        }
        result += remainder.toString().padStart(minutes > 0 ? 2 : 1, "0");
        if (remainder === 1) result += " Sekunde";
        else result += " Sekunden";

        return result;
      });
      // -------------------------------------------------------

      return {
        // AppointmentType
        optionsAT,
        currentOptionAT,
        currentSelectionAT,

        // Suggestions
        createSuggestions,
        pendingSuggestions,
        suggestions,
        suggestionsLoaded,
        pillarCalendar,

        // SearchFrom
        searchFrom,
        additionalLabelForPillar,
        loadNextSuggestions,
        maxDaySearched,

        // Persons
        hasPersonSelector,
        currentSelectionP,
        optionsP,

        // Reservation
        reservedForSeconds,
        reservedFormatted,
      };
    },
  });
</script>

<template>
  <div class="vue-component--patient-create-appointment">
    <FormSelect
      v-model="currentSelectionAT"
      label="Welcher Besuchsgrund?"
      v-bind:options="optionsAT"
      v-bind:disabled="pendingSuggestions"
    />
    <template v-if="currentOptionAT">
      <div
        v-if="currentOptionAT.description_html"
        class="padded-box patient-create-appointment__description"
      >
        <strong>Beschreibung:</strong> <br />
        <!-- eslint-disable vue/no-v-html -- sanitized field -->
        <span v-html="currentOptionAT.description_html"></span>
        <!-- eslint-enable -->
      </div>

      <template v-if="hasPersonSelector">
        <FormSelect
          v-model="currentSelectionP"
          v-bind:options="optionsP"
          label="Arztauswahl (optional)"
          include-blank="-- Keine Präferenz --"
        />
      </template>

      <FormDatetime
        v-model="searchFrom"
        label="Termin suchen ab"
        visual-small
        v-bind:min-date="currentOptionAT.allowed_booking_range_from"
        v-bind:max-date="currentOptionAT.allowed_booking_range_to"
        v-bind:allow-deletion="false"
        v-bind:disabled="pendingSuggestions || suggestionsLoaded"
      />

      <div
        v-if="!suggestionsLoaded || !suggestions"
        class="navigation-button__container"
      >
        <BaseButton
          v-if="!pendingSuggestions"
          visual-full-width
          v-bind:disabled="pendingSuggestions"
          v-on:submit="createSuggestions"
        >
          Terminvorschläge
        </BaseButton>
      </div>
    </template>

    <template v-if="pendingSuggestions">
      <div class="spinner__container"><BaseSpinner class="spinner" /></div>
    </template>

    <template v-if="suggestions && suggestions.length > 0">
      <PillarCalendar
        label="Datum &amp; Uhrzeit wählen"
        v-bind:pillar-calendar="pillarCalendar"
        v-bind:additional-label="additionalLabelForPillar"
        v-on:click:additional-label="loadNextSuggestions"
      />
      <div
        v-if="reservedForSeconds || reservedForSeconds === 0"
        class="patient-create-appointment__reservation"
        v-bind:class="{
          'patient-create-appointment__reservation--expired':
            !reservedFormatted,
        }"
      >
        <template v-if="reservedFormatted">
          Die Termine sind noch für
          {{ reservedFormatted }} für Sie reserviert.
        </template>
        <template v-else>
          Die exklusive Reservierung der angebotenen Termine ist leider
          abgelaufen.
        </template>
      </div>
    </template>
    <template
      v-else-if="suggestionsLoaded && suggestions && suggestions.length <= 0"
    >
      <div class="patient-create-appointment__no-suggestions">
        Derzeit stehen leider keine freien Termine für "{{
          currentOptionAT?.name
        }}" zur Verfügung.
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";
  @use "frontend/styles/mixins/link";

  .padded-box {
    padding: 10px 0;
  }

  .navigation-button__container {
    padding: 15px 0;
    width: 190px;
    margin: 0 auto; // center horizontally

    text-align: center;
  }

  .patient-create-appointment__no-suggestions {
    @include label.label;
    @include label.label--error;
  }

  .patient-create-appointment__description {
    ::v-deep(a) {
      @include link.link;
    }
  }

  .spinner__container {
    display: flex;
    justify-content: center;

    margin-top: 15px;
    margin-bottom: 15px;
  }

  .spinner {
    width: 20px;
  }

  .patient-create-appointment__reservation {
    @include label.label;

    margin-top: 15px;
  }

  .patient-create-appointment__reservation--expired {
    @include label.label--error;
  }
</style>
