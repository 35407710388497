<script lang="ts">
  import {
    computed,
    defineComponent,
    PropType,
    ref,
    toRefs,
    unref,
    watch,
  } from "vue";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import {
    Droplet,
    Pillar,
    PillarCalendar,
  } from "frontend/interfaces/pillar-calendar";
  import { StyleValue } from "frontend/interfaces/template";
  import { getPillarIndex } from "frontend/uses/use-pillar-calendar";

  import BaseBox from "frontend/components/base/BaseBox.vue";

  const DEFAULT_NUMBER_OF_PILLARS = 2;

  export default defineComponent({
    components: { BaseBox },
    props: {
      pillarCalendar: {
        type: Object as PropType<PillarCalendar>,
        required: true,
      },
      selectedPillar: {
        type: Object as PropType<Pillar | null>,
        default: null,
      },
    },
    setup(props) {
      const { pillarCalendar, selectedPillar } = toRefs(props);
      const numberOfPillars = ref<number>(DEFAULT_NUMBER_OF_PILLARS);
      const pillarIndex = ref<number>(
        getPillarIndex(pillarCalendar, selectedPillar)
      );

      watch(selectedPillar, () => {
        if (
          selectedPillar.value &&
          !activePillars.value.includes(selectedPillar.value)
        ) {
          pillarIndex.value = getPillarIndex(pillarCalendar, selectedPillar);
        }
      });

      const totalNumberOfPillars = computed<number>(() => {
        return unref(pillarCalendar).pillars.length;
      });

      const activePillars = computed<Array<Pillar>>(() => {
        return unref(pillarCalendar).pillars.slice(
          unref(pillarIndex),
          unref(pillarIndex) + unref(numberOfPillars)
        );
      });
      const numberOfDropletRows = computed<number>(() => {
        return Math.max(
          ...unref(activePillars).map((pillar) => pillar.droplets.length)
        );
      });
      const calculateNumberOfFillers = (droplets: Array<Droplet>) => {
        return Math.max(0, unref(numberOfDropletRows) - droplets.length);
      };
      const stylingGrid = computed<StyleValue>(() => {
        return {
          "grid-template-columns": `auto repeat(${unref(
            numberOfPillars
          )}, 1fr) auto`,
          "grid-template-rows": `auto repeat(${unref(
            numberOfDropletRows
          )}, auto)`,
        };
      });
      const stylingSpacer = computed<StyleValue>(() => {
        return {
          "grid-row": `2 / ${unref(numberOfDropletRows) + 2}`,
        };
      });
      const leftNavigationAvailable = computed<boolean>(() => {
        return unref(pillarIndex) !== 0;
      });
      const rightNavigationAvailable = computed<boolean>(() => {
        return (
          unref(pillarIndex) + unref(numberOfPillars) <
          unref(totalNumberOfPillars)
        );
      });
      const navigate = (direction: number) => {
        const candidatePillarIndex = unref(pillarIndex) + direction;
        pillarIndex.value = Math.max(
          0,
          Math.min(
            candidatePillarIndex,
            unref(totalNumberOfPillars) - unref(numberOfPillars)
          )
        );
      };
      const navigateOneSheet = (direction: number) => {
        navigate(Math.sign(direction) * unref(numberOfPillars));
      };
      return {
        stylingGrid,
        stylingSpacer,
        leftNavigationAvailable,
        rightNavigationAvailable,
        navigate,
        navigateOneSheet,
        activePillars,
        numberOfDropletRows,
        calculateNumberOfFillers,
        DateFormat,
        formatDate,
      };
    },
  });
</script>

<template>
  <div>
    <BaseBox visual-no-heading>
      <div class="pillar-calendar" v-bind:style="stylingGrid">
        <!-- left navigation -->
        <div
          v-if="leftNavigationAvailable"
          class="pillar-calendar__header pillar-navigation pillar-navigation--left pillar-calendar__cell"
        >
          <i class="fe fe-arrow-left" v-on:click="navigate(-1)"></i>
        </div>
        <div v-bind:style="stylingSpacer" class="pillar-calendar__cell"></div>

        <!-- main calendar -->
        <template v-for="pillar in activePillars" v-bind:key="pillar.id">
          <div
            class="pillar-calendar__header pillar-calendar__cell"
            v-bind:class="{
              'pillar-calendar__cell--selected':
                selectedPillar?.id === pillar.id,
            }"
          >
            <div class="pillar-calendar__title">
              {{ formatDate(pillar.day, DateFormat.WeekdayShortOnly) }}
            </div>
            <div class="pillar-calendar__sub-title">
              {{ formatDate(pillar.day, DateFormat.DateOnlyWithoutYear) }}
            </div>
          </div>
          <div
            v-for="(droplet, dropletIndex) in pillar.droplets"
            v-bind:key="droplet.id"
            class="pillar-calendar__cell"
          >
            <div
              class="pillar-calendar__droplet"
              v-bind:class="{
                'pillar-calendar__droplet--first': dropletIndex === 0,
              }"
              v-on:click="droplet.onClick"
            >
              {{ formatDate(droplet.time, DateFormat.TimeOnlyWithoutSeconds) }}
            </div>
          </div>
          <!-- fill with empty spots -->
          <div
            v-for="index in calculateNumberOfFillers(pillar.droplets)"
            v-bind:key="index"
            class="pillar-calendar__cell"
          >
            <div class="pilar-calendar__empty-droplet"></div>
          </div>
        </template>

        <!-- right navigation -->
        <div
          v-if="rightNavigationAvailable"
          class="pillar-calendar__header pillar-navigation pillar-navigation--right pillar-calendar__cell"
        >
          <i class="fe fe-arrow-right" v-on:click="navigate(1)"></i>
        </div>
        <div v-bind:style="stylingSpacer" class="pillar-calendar__cell"></div>
      </div>
    </BaseBox>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";

  .pillar-navigation {
    font-size: 25px;

    cursor: pointer;

    display: flex;
    align-items: center;
  }

  .pillar-calendar {
    display: grid;
    grid-auto-flow: column;
  }

  .pillar-calendar__cell {
    padding: 5px;

    text-align: center;
  }

  .pillar-calendar__droplet {
    display: inline-block;

    font-weight: bold;

    padding: 3px 8px;
    border-radius: dimensions.$dimension_pillar-calendar--droplet-radius;

    background-color: colors.$color_pillar-calendar__droplet--background;
    color: colors.$color_pillar-calendar__droplet--text;

    cursor: pointer;

    &:hover {
      background-color: color.scale(
        colors.$color_pillar-calendar__droplet--background,
        $lightness: -15%
      );
    }
  }

  .pilar-calendar__empty-droplet {
    display: inline-block;
    width: 20px;
    height: 3px;
    background-color: colors.$color_pillar-calendar__droplet-empty--background;
  }

  .pillar-calendar__header {
    border-bottom: 1px solid colors.$color_pillar-calendar__heading-separator;
  }

  .pillar-calendar__title,
  .pillar-calendar__sub-title {
    font-size: 14px;
    line-height: 1.3;
  }
  .pillar-calendar__title {
    font-weight: bold;
  }

  .pillar-calendar__droplet--first {
    margin-top: 15px;
  }

  .pillar-calendar__cell--selected {
    font-weight: bold;
    color: colors.$color_pillar-calendar__droplet--background;
  }
</style>
