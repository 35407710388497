<script lang="ts" setup>
  import { reactive, ref } from "vue";

  import {
    StatusValue,
    SUCCESS_TIMEOUT,
  } from "frontend/utils/animation-status";
  import { Unit } from "frontend/interfaces/unit";
  import { formatDate, DateFormat } from "shared/utils/date-utils";
  import { updateUnitUnified } from "frontend/api/application/request-unit";
  import { Errors } from "frontend/uses/use-errors";
  import { parseUnit } from "frontend/parser/parse-unit";
  import {
    SingletonTimeout,
    useSingletonTimeout,
  } from "frontend/uses/use-singleton-timeout";
  import { assertUnreachable } from "shared/utils/typescript-helper";

  import BaseColumns from "frontend/components/base/BaseColumns.vue";
  import FormText from "frontend/components/form/FormText.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import FormOfficeColor from "frontend/components/form/FormOfficeColor.vue";
  import ShowPatient from "frontend/components/ShowPatient.vue";
  import ShowSimpleUnit from "frontend/components/ShowSimpleUnit.vue";
  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  type EditableKeys = "name" | "color_id";
  type Editable = {
    [Key in EditableKeys]: {
      isEditing: boolean;
      currentValue: Unit[Key];
      status: StatusValue;
      timeout: SingletonTimeout;
      errors?: Errors;
    };
  };

  const props = withDefaults(
    defineProps<{
      currentUnit: Unit;
      enableEditing?: boolean;
    }>(),
    { enableEditing: false }
  );

  const emit = defineEmits<{
    (e: "changed:currentUnit:name", value: Unit["name"]): void;
    (
      e: "changed:currentUnit:color_id",
      value: Unit["color_id"],
      hex: Unit["color"],
      effective: Unit["effective_color_hex"]
    ): void;
  }>();

  const isSaving = ref<boolean>(false);
  const editable = reactive<Editable>({
    name: {
      isEditing: false,
      currentValue: null,
      status: StatusValue.NoStatus,
      timeout: useSingletonTimeout(),
    },
    color_id: {
      isEditing: false,
      currentValue: null,
      status: StatusValue.NoStatus,
      timeout: useSingletonTimeout(),
    },
  });
  const doStartEditing = (key: EditableKeys) => {
    if (!props.enableEditing) return;

    for (const propOptsKey in editable) {
      editable[propOptsKey as EditableKeys].isEditing = false;
    }

    const propertyOptions = editable[key];
    propertyOptions.isEditing = true;
    propertyOptions.currentValue = props.currentUnit[key];
  };
  const doStopEditing = async (key: EditableKeys) => {
    if (isSaving.value) return;

    const eventName = `changed:currentUnit:${key}` as const;

    try {
      isSaving.value = true;

      const propertyOptions = editable[key];
      propertyOptions.timeout.cancel();
      propertyOptions.status = StatusValue.Pending;

      const { success, errors, entityRaw } = await updateUnitUnified({
        [key]: propertyOptions.currentValue,
        id: props.currentUnit.id,
      });

      const newUnit = parseUnit(entityRaw);
      propertyOptions.currentValue = newUnit[key];

      if (success) {
        propertyOptions.status = StatusValue.Success;
        propertyOptions.timeout.reset(() => {
          propertyOptions.status = StatusValue.NoStatus;
        }, SUCCESS_TIMEOUT);
        propertyOptions.isEditing = false;
        switch (eventName) {
          case "changed:currentUnit:name":
            emit(eventName, newUnit.name);
            break;
          case "changed:currentUnit:color_id":
            emit(
              eventName,
              newUnit.color_id,
              newUnit.color,
              newUnit.effective_color_hex
            );
            break;
          default:
            assertUnreachable(eventName);
        }
      } else {
        propertyOptions.errors = errors.name;
        propertyOptions.status = StatusValue.Failure;
      }
    } finally {
      isSaving.value = false;
    }
  };
</script>

<template>
  <div>
    <template v-if="currentUnit">
      <BaseColumns>
        <template v-if="currentUnit.appointment">
          <FormText label="Termintyp">
            {{ currentUnit.appointment.appointment_type.name }}
          </FormText>

          <h5>Einzeltermine</h5>

          <div class="show-unit__appointment-container">
            <div class="show-unit__appointment-column">
              <ShowSimpleUnit
                class="show-unit__main-unit"
                v-bind:unit="currentUnit"
              />
            </div>

            <template v-if="currentUnit.appointment.units.length > 0">
              <div class="show-unit__appointment-column">
                <div class="show-unit__units">
                  <div
                    v-for="unit in currentUnit.appointment.units"
                    v-bind:key="unit.id"
                    class="show-unit__unit"
                  >
                    <ShowSimpleUnit v-bind:unit="unit" />
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div>
            <h5>Einbestellzeit</h5>
            {{
              formatDate(
                currentUnit.appointment.begin_for_patient,
                DateFormat.DateAndTime
              )
            }}
          </div>

          <ShowPatient short v-bind:patient="currentUnit.appointment.patient" />

          <FormText v-if="currentUnit.appointment.message" label="Nachricht">
            {{ currentUnit.appointment.message }}
          </FormText>
        </template>
        <template v-else>
          <FormText v-if="!editable.name.isEditing" label="Name">
            <div class="name-container">
              <span class="name-text">
                {{ currentUnit.name }}
              </span>
              <i
                v-if="enableEditing"
                class="fe fe-pencil edit-action"
                v-on:click="doStartEditing('name')"
              ></i>
              <AnimationStatus v-bind:animation-status="editable.name.status" />
            </div>
          </FormText>
          <FormInput
            v-else
            v-model="editable.name.currentValue"
            label="Name"
            v-bind:disabled="isSaving"
            v-bind:animation-status="editable.name.status"
            v-bind:errors="editable.name.errors"
            v-on:autosave="doStopEditing('name')"
          />
          <h5>Einzeltermine</h5>
          <div class="show-unit__appointment-container">
            <div class="show-unit__appointment-column">
              <ShowSimpleUnit
                class="show-unit__main-unit"
                v-bind:unit="currentUnit"
              />
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <slot name="actions"></slot>
          <template v-if="enableEditing">
            <FormOfficeColor
              v-if="editable.color_id.isEditing"
              v-model="editable.color_id.currentValue"
              label="Farbe des Einzeltermins"
              v-bind:animation-status="editable.color_id.status"
              allow-blank
              v-on:autosave="doStopEditing('color_id')"
            />
            <div
              v-else
              class="choose-color"
              v-on:click="doStartEditing('color_id')"
            >
              Farbe ändern
              <i class="fe fe-pencil edit-action"></i>
              <AnimationStatus
                v-bind:animation-status="editable.color_id.status"
              />
            </div>
          </template>
        </template>
      </BaseColumns>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .show-unit__units {
    .show-unit__unit {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .show-unit__appointment-container {
    display: flex;
  }

  .show-unit__appointment-column {
    margin-right: 25px;

    flex: 1;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .show-unit__heading--no-lower-distance {
    margin-bottom: 0;
  }

  .edit-action,
  .choose-color {
    color: colors.$color_edit-action;
    cursor: pointer;
  }

  .edit-action {
    margin-left: 5px;
  }

  .choose-color {
    font-size: 12px;
    margin-top: 10px;
  }

  .name-container,
  .choose-color {
    display: flex;
    align-items: center;
  }

  .name-text {
    flex: 1;
  }
</style>
