<script lang="ts" setup>
  import { computed, nextTick, onMounted, ref, watch } from "vue";

  import { CalendarEntryUnit } from "frontend/interfaces/calendar";
  import { useStore as useProfileStore } from "frontend/stores/profile";
  import {
    defineEmitsEntry,
    definePropsEntry,
  } from "frontend/utils/base-calendar-utils";
  import {
    conditionalContextMenuEntry,
    useContextMenu,
  } from "shared/utils/context-menu/use-context-menu";
  import { hasOverflow } from "shared/utils/overflow";

  const DEFAULT_FONT_SIZE = 14; // px
  const MIN_FONT_SIZE = 9; // px

  const props = defineProps(definePropsEntry<CalendarEntryUnit>());
  defineEmits(defineEmitsEntry());

  const profileStore = useProfileStore();

  // ---------------------------------------------------------------------------
  // automatic adjustment of font size
  // ---------------------------------------------------------------------------
  const fontSize = ref<number>(DEFAULT_FONT_SIZE);
  const overflowProbeElement = ref<HTMLElement | null>(null);
  const rootProbeElement = ref<HTMLElement | null>(null);
  const adjustFontSize = (container: HTMLElement, content: HTMLElement) => {
    if (
      hasOverflow(container, content, { bottom: true }) &&
      fontSize.value > MIN_FONT_SIZE
    ) {
      fontSize.value = Math.max(MIN_FONT_SIZE, fontSize.value - 1);
      setTimeout(adjustFontSizeOfContent, 0);
    }
  };
  const adjustFontSizeOfContent = () => {
    if (overflowProbeElement.value && rootProbeElement.value)
      adjustFontSize(rootProbeElement.value, overflowProbeElement.value);
  };
  onMounted(adjustFontSizeOfContent);
  watch(
    () =>
      props.isHovered +
      (props.entry.boldLabel ?? "") +
      props.entry.label +
      profileStore.profile.calendar_timescale_in_minutes,
    async () => {
      fontSize.value = DEFAULT_FONT_SIZE;
      await nextTick();

      adjustFontSizeOfContent();
    }
  );
  const stylingForOverflow = computed(() => ({
    "font-size": `${fontSize.value}px`,
  }));
  const stylingForOverflowIcons = computed(() => ({
    "font-size": `${Math.floor(fontSize.value * 1.4)}px`,
  }));
  const disablePadding = computed(() => fontSize.value < DEFAULT_FONT_SIZE);
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // Setup context-menu
  // ---------------------------------------------------------------------------
  const ctx = useContextMenu();
  if (props.entry.ctxAppointmentStatusPatientArrived)
    conditionalContextMenuEntry(
      ctx,
      props.entry.ctxAppointmentStatusPatientArrived
    );
  if (props.entry.ctxAppointmentStatusPatientNotArrived)
    conditionalContextMenuEntry(
      ctx,
      props.entry.ctxAppointmentStatusPatientNotArrived
    );
  if (props.entry.ctxAppointmentStatusDone)
    conditionalContextMenuEntry(ctx, props.entry.ctxAppointmentStatusDone);
  if (props.entry.ctxCutAppointment)
    conditionalContextMenuEntry(ctx, props.entry.ctxCutAppointment);
  if (props.entry.ctxDeleteAppointment)
    conditionalContextMenuEntry(ctx, props.entry.ctxDeleteAppointment);
  if (props.entry.ctxAppointmentSuggestions)
    conditionalContextMenuEntry(ctx, props.entry.ctxAppointmentSuggestions);
  if (props.entry.ctxOpenPatientProfile)
    conditionalContextMenuEntry(ctx, props.entry.ctxOpenPatientProfile);
  if (props.entry.ctxOpenPatientInPVS)
    conditionalContextMenuEntry(ctx, props.entry.ctxOpenPatientInPVS);
  // ---------------------------------------------------------------------------
</script>

<template>
  <div
    class="unit"
    v-bind:style="stylingForOverflow"
    v-bind:class="{
      'unit--disabled': entry?.disabled,
      'unit--hover-on': isHovered,
      'unit--highlight-background': entry?.highlightBackground,
    }"
  >
    <div
      class="unit__badge"
      v-bind:style="{
        'background-color': entry?.color,
      }"
    ></div>
    <div
      ref="rootProbeElement"
      class="unit__content"
      v-bind:class="{ 'unit__content--no-padding': disablePadding }"
    >
      <span class="unit__time">{{ entry?.timeLabel }}</span>
      <span ref="overflowProbeElement" class="unit__patient">
        <span v-if="entry?.boldLabel" class="unit__patient__last-name">
          {{ entry?.boldLabel }}
        </span>
        {{ entry?.label }} </span
      ><br />
      <span v-if="entry?.description">{{ entry.description }}</span>
    </div>

    <div
      class="unit__icons"
      v-bind:class="{
        'unit__icons--stacked': (entry?.heightPercentage || 0) >= 0.55,
        'unit__icons--no-padding': disablePadding,
      }"
    >
      <i
        v-if="entry?.showCheckmark"
        v-tooltip="'Termin ist abgeschlossen'"
        v-bind:style="stylingForOverflowIcons"
        class="fe fe-checkmark unit__icon"
      ></i>
      <i
        v-if="entry?.iconNotArrived"
        v-tooltip="'Patient nicht erschienen'"
        v-bind:style="stylingForOverflowIcons"
        class="fe fe-person--off unit__icon"
      ></i>

      <i
        v-if="entry?.iconHasPrivate"
        v-tooltip="'Patient hat private Versicherung'"
        v-bind:style="stylingForOverflowIcons"
        class="fe fe-star--with-badge unit__icon"
      ></i>

      <i
        v-if="entry?.iconHasPraxisApp"
        v-tooltip="'Nutzer hat PraxisApp'"
        v-bind:style="stylingForOverflowIcons"
        class="fe fe-smartphone icon--has-praxisapp"
      ></i>

      <i
        v-if="entry?.iconSmartphoneTouch"
        v-tooltip="'Termin durch Nutzer gebucht'"
        v-bind:style="stylingForOverflowIcons"
        class="fe fe-smartphone--touch unit__icon"
      ></i>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/features";

  .unit {
    display: flex;

    background-color: colors.$color_calendar__unit--background;

    font-size: 14px;

    overflow: hidden;

    // &:hover,
    &.unit--hover-on {
      outline: 3px solid colors.$color_calendar__unit--hover-on--outline;
    }
  }

  .unit--highlight-background {
    background-color: colors.$color_calendar__unit--highlight-background;
  }

  .unit__icon {
    font-size: 20px;
    color: colors.$color_calendar__unit__icon--text;
  }
  .praxis_icon {
    font-size: 40px;
  }

  .unit--disabled {
    background-color: colors.$color_calendar__unit__disabled--background;
    cursor: not-allowed;
    color: colors.$color_calendar__unit__disabled--text;
  }

  .unit__badge {
    width: 7px;
    margin-right: 8px;
  }

  .unit__content {
    padding: 5px 0;
    flex: 1;

    overflow: hidden;

    &--no-padding {
      padding: 1px 0;
    }
  }
  .unit__icons {
    padding: 5px;
    font-size: 18px;

    &--no-padding {
      padding: 1px 0;
    }
  }

  .unit__icons--stacked {
    i {
      display: block;
    }
  }

  .unit__time {
    display: inline-block;
    width: 36px;

    margin-right: 12px;
  }

  .unit__patient__last-name {
    @if features.$feature_calendar-patient--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
  }
  .unit--placeholder {
    background-color: transparent;
  }

  .icon--has-praxisapp {
    font-size: 20px;
    color: colors.$color_calendar__unit__praxisapp-icon--text;
  }
</style>
