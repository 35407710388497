<script lang="ts" setup>
  import { computed } from "vue";

  import {
    ContextMenuAPI,
    ContextMenuEntry,
  } from "shared/utils/context-menu/interfaces";

  const props = withDefaults(
    defineProps<{
      entries: Array<ContextMenuEntry>;
      api: ContextMenuAPI;
    }>(),
    {
      entries: () => [],
    }
  );

  const onTriggerEntry = (entry: ContextMenuEntry) => {
    if (entry.onTrigger) entry.onTrigger(props.api); // trigger action
  };

  const sortedEntries = computed(() =>
    [...props.entries].sort((a, b) => a.position - b.position)
  );
</script>

<template>
  <div class="context-menu">
    <div
      v-for="(entry, index) in sortedEntries"
      v-bind:key="index"
      v-bind:class="{
        'context-menu__category--divider':
          index !== 0 && entry.category !== sortedEntries[index - 1].category,
      }"
      v-on:click="onTriggerEntry(entry)"
    >
      <component
        v-bind:is="entry.component"
        v-bind:options="entry.options"
        v-bind:is-hovered="true"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  @use "sass:color";
  @use "shared/styles/colors";

  .context-menu {
    margin-top: 1px; // prevent instant-hover on opening

    background-color: colors.$color_context-menu--background;
    box-shadow: 0 0 4px 1px
      #{color.scale(colors.$color_context-menu--shadow, $alpha: -50%)};
    color: colors.$color_context-menu--text;
    font-size: 14px;
    min-width: 150px;
  }

  .context-menu__category--divider {
    border-top: 1px solid colors.$color_base-list--separator;
  }
</style>
