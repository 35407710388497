<script lang="ts">
  import {
    computed,
    defineComponent,
    PropType,
    ref,
    CSSProperties,
    toRefs,
    unref,
    watch,
    nextTick,
  } from "vue";
  import { v4 as uuid } from "uuid";

  import {
    Calendar,
    CalendarCell,
    CalendarEntry,
  } from "frontend/interfaces/calendar";
  import {
    locatePositionFromEvent,
    stylesForNote,
  } from "frontend/utils/base-calendar-utils";
  import { log, LogLevel } from "shared/utils/logger";
  import { findOffset, useStickyness } from "frontend/uses/use-stickyness";
  import { ElementProvider } from "frontend/utils/provider";
  import { useResponsiveness } from "frontend/uses/use-responsiveness";
  import { ContextMenuControl } from "shared/utils/context-menu/interfaces";

  import BCCell from "frontend/components/base-calendar/BCCell.vue";

  export default defineComponent({
    components: { BCCell },
    props: {
      calendar: {
        type: Object as PropType<Calendar | null>,
        default: null,
      },
      enableDragAndDrop: {
        type: Boolean,
        default: false,
      },
      enableResize: {
        type: Boolean,
        default: false,
      },
      enableClick: {
        type: Boolean,
        default: false,
      },
      enableNowIndicator: {
        // TODO: only possible if the calendar is profiled
        // otherwise it will not work
        type: Boolean,
        default: false,
      },
      initialHighlight: {
        type: String,
        default: null,
      },
      visualStickyHeaderElement: {
        type: Object as PropType<HTMLElement | null>,
        default: null,
      },
      noteTitle: {
        type: String,
        default: "Ganztägig",
      },
      noteSticky: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["entry", "refresh-needed"],
    setup(props, { emit }) {
      const { calendar, enableDragAndDrop, enableClick, initialHighlight } =
        toRefs(props);
      const scrollEnabled = ref<boolean>(true);
      const styles = computed<CSSProperties | undefined>(() => {
        const calendarUnref = unref(calendar);
        if (!calendarUnref) return;
        const gridTemplateColumns = `70px${" 1fr".repeat(
          calendarUnref.columnHeaders.length
        )}`;
        return {
          display: "grid",
          gridTemplateColumns,
          color: "black",
          maxWidth:
            calendarUnref.columnHeaders.length === 1
              ? "calc(100% - 300px)"
              : undefined,
        };
      });
      // ------------------------------------------------
      // CELL CLICK
      // ------------------------------------------------
      const onCellClick = (event: MouseEvent, cell: CalendarCell) => {
        if (!enableClick.value || !cell.onClick) return;
        const eventPosition = locatePositionFromEvent(event);
        if (eventPosition) cell.onClick(eventPosition);
        else log(LogLevel.Error, "[BASE-CALENDAR] could not locate event!");
      };
      const onCellCtx = (
        event: MouseEvent,
        cell: CalendarCell,
        ctx: ContextMenuControl
      ) => {
        const location = locatePositionFromEvent(event);
        ctx.setEventPosition(location);
        ctx.setEventElement(cell);
      };
      // ------------------------------------------------
      // DRAG AND DROP
      // ------------------------------------------------
      const DATA_TRANSFER_KEY = "eventID";
      const currentlyDragged: Partial<Record<string, DragStorage>> = {};
      const onCellDrop = (event: DragEvent, cell: CalendarCell) => {
        if (!enableDragAndDrop.value) return;
        if (event.dataTransfer) {
          const eventID = event.dataTransfer.getData(DATA_TRANSFER_KEY);
          if (eventID) {
            const dropStorage = currentlyDragged[eventID];
            if (!dropStorage) return;
            const entry = dropStorage.entry;
            if (entry && entry.onDrop) {
              const location = locatePositionFromEvent(event, {
                event: dropStorage.dragStartEvent,
                reference: "top",
              });
              if (location) {
                entry.onDrop(cell, location);
              }
            }
          }
        }
      };
      const onEntryDragStart = (event: DragEvent, entry: CalendarEntry) => {
        if (!enableDragAndDrop.value) return;
        // already dragging that entry?
        if (
          (Object.values(currentlyDragged) as DragStorage[])
            .map((dropStorage) => dropStorage.entry)
            .indexOf(entry) >= 0
        ) {
          log(LogLevel.Warn, "already dragging entry", entry);
          event.preventDefault();
          return;
        }
        if (event.dataTransfer) {
          event.dataTransfer.dropEffect = "move";
          event.dataTransfer.effectAllowed = "move";
          const eventID = uuid();
          currentlyDragged[eventID] = { entry, dragStartEvent: event };
          event.dataTransfer.setData(DATA_TRANSFER_KEY, eventID);
        }
      };
      const onEntryDragEnd = (event: DragEvent, entry: CalendarEntry) => {
        if (!enableDragAndDrop.value) return;
        for (const [key, value] of Object.entries(currentlyDragged)) {
          if ((value as DragStorage).entry === entry) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- legacy implementation, maybe fix upon touching
            delete currentlyDragged[key];
          }
        }
        scrollEnabled.value = false;
      };
      // ------------------------------------------------
      // SCROLL
      // ------------------------------------------------
      //scroll if we have an initial highlight
      const scrollToInitialHighlight = async () => {
        if (!initialHighlight.value) return;
        await nextTick();
        const offset = Math.max(
          Math.min(
            ...Array.from(
              document.querySelectorAll(
                `[data-hover-on="${initialHighlight.value}"]`
              )
            ).map((entry) => entry.getBoundingClientRect().top)
          ) -
            window.innerHeight / 2,
          0
        );
        window.scrollTo({
          top: offset,
        });
      };
      // ------------------------------------------------
      // STICKINESS
      // ------------------------------------------------
      // sticky stuff for column header
      const headerElement = ElementProvider.localInjectSafe(
        ElementProvider.provisions().baseHeader
      );
      const headerReferenceElement = computed(
        () => props.visualStickyHeaderElement ?? headerElement.value
      );
      const {
        targetCSS: headerStickyCSS,
        targetElement: stickyElement,
        isStuck: headerIsStuck,
      } = useStickyness(headerReferenceElement, () => 0);
      const { targetCSS: noteStickyCSS } = useStickyness(
        headerReferenceElement,
        () => findOffset(stickyElement.value)
      );
      // ------------------------------------------------
      // RESPONSIVENESS
      // ------------------------------------------------
      const columnsWidth = ref<Array<number>>([]);
      const rootElement = ref<HTMLElement | null>(null);
      const noteElement = ref<Array<HTMLElement | null> | null>(null);
      useResponsiveness(() => {
        setTimeout(() => {
          if (!rootElement.value) return;
          if (!noteElement.value) return;
          columnsWidth.value = noteElement.value.map((el) =>
            el ? el.offsetWidth : 0
          );
        }, 100);
      }, rootElement);
      // ------------------------------------------------
      // HOVER
      // ------------------------------------------------
      const currentHoverOn = ref<string | null>(null);
      const onHover = (hoverOn?: string): void => {
        currentHoverOn.value = hoverOn ?? null;
      };
      // ------------------------------------------------
      // WATCHERS
      // ------------------------------------------------
      watch(
        [calendar],
        () => {
          if (calendar.value && calendar.value.onCalendarInit) {
            calendar.value.onCalendarInit({
              triggerRefreshNeeded: () => {
                emit("refresh-needed");
              },
            });
            if (scrollEnabled.value) scrollToInitialHighlight();
          }
        },
        { immediate: true }
      );
      watch(initialHighlight, () => {
        scrollEnabled.value = true;
        scrollToInitialHighlight();
      });

      return {
        styles,
        headerStickyCSS,
        noteStickyCSS,
        stickyElement,
        headerIsStuck,
        stylesForNote,

        rootElement,
        noteElement,
        columnsWidth,
        currentHoverOn,

        onCellClick,
        onCellDrop,
        onCellCtx,
        onEntryDragStart,
        onEntryDragEnd,
        onHover,
      };
    },
  });

  interface DragStorage {
    entry: CalendarEntry;
    dragStartEvent: DragEvent;
  }
</script>

<template>
  <div
    ref="rootElement"
    class="vue-component__base-calendar calendar"
    v-bind:style="styles"
  >
    <template v-if="calendar">
      <!-- Calendar header -->
      <div
        ref="stickyElement"
        class="calendar__cell calendar__cell--top calendar__cell--left calendar__peak-cell"
        v-bind:class="{ 'calendar__cell--stuck': headerIsStuck }"
        v-bind:style="headerStickyCSS"
      >
        <i class="fe fe-alarm"></i>
      </div>
      <div
        v-for="(header, index) in calendar.columnHeaders"
        v-bind:key="header.id"
        class="calendar__cell calendar__cell--top calendar__person"
        v-bind:class="{
          'calendar__cell--right': index + 1 === calendar.columnHeaders.length,
          'calendar__cell--stuck': headerIsStuck,
        }"
        v-bind:style="headerStickyCSS"
      >
        {{ header.label }}
      </div>

      <!-- Calendar notes -->
      <template v-if="noteTitle">
        <div
          class="calendar__cell calendar__cell--left calendar__note__cell calendar__note__cell__all-day"
          v-bind:class="{
            'calendar__cell--stuck': headerIsStuck && noteSticky,
          }"
          v-bind:style="noteSticky ? noteStickyCSS : undefined"
        >
          {{ noteTitle }}
        </div>
        <div
          v-for="(notes, index) in calendar.columnNotes"
          v-bind:key="index"
          ref="noteElement"
          class="calendar__cell calendar__note__cell"
          v-bind:class="{
            'calendar__cell--stuck': headerIsStuck && noteSticky,
          }"
          v-bind:style="noteSticky ? noteStickyCSS : undefined"
        >
          <div
            v-for="note in notes"
            v-bind:key="note.id"
            class="calendar__note-container"
          >
            <div
              v-if="note && note.active"
              class="calendar__note"
              v-bind:class="{
                'calendar__note__color-red': note.color === 'red',
                'calendar__note__color-green': note.color === 'green',
                'calendar__note__starts-before': note.startsBefore,
                'calendar__note__ends-after': note.endsAfter,
              }"
              v-bind:style="
                stylesForNote(index, index + note.widthOffset, columnsWidth)
              "
            >
              {{ note.label }}
            </div>
            <div class="hidden">{{ note.label }}</div>
            &nbsp;
          </div>
        </div>
      </template>

      <!-- calendar rows -->
      <template v-for="(row, index) in calendar.rows" v-bind:key="row.key">
        <div
          class="calendar__cell calendar__cell--left calendar__time-label"
          v-bind:class="{
            'calendar__cell--bottom': index + 1 === calendar.rows.length,
          }"
        >
          {{ row.label }}
        </div>
        <!-- calendar cells -->
        <div
          v-for="(cell, indexCell) in row.cells"
          v-bind:key="cell.key"
          v-bind:ref="(el) => (cell.element = el as HTMLElement | undefined)"
          v-bind:class="{
            'calendar__cell--right': indexCell + 1 === row.cells.length,
            'calendar__cell--bottom': index + 1 === calendar.rows.length,
          }"
        >
          <BCCell
            v-bind:calendar="calendar"
            v-bind:row="row"
            v-bind:cell="cell"
            v-bind:enable-resize="enableResize"
            v-bind:initial-highlight="initialHighlight"
            v-bind:current-hover="currentHoverOn"
            v-bind:enable-drag-and-drop="enableDragAndDrop"
            v-bind:enable-now-indicator="enableNowIndicator"
            v-on:drag-start="onEntryDragStart"
            v-on:drag-end="onEntryDragEnd"
            v-on:cell-click="onCellClick"
            v-on:cell-drop="onCellDrop"
            v-on:cell-ctx="onCellCtx"
            v-on:hover="onHover"
            v-on:entry="(entry) => $emit('entry', entry)"
            v-on:refresh-needed="$emit('refresh-needed')"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/features";
  @use "sass:color";

  .calendar {
    display: grid;

    background-color: colors.$color_box--background;

    border-radius: dimensions.$dimension_box--calendar-radius;
  }

  .calendar__cell {
    height: 100%;

    // positioning inside
    position: relative;

    // NOTE: If you change the width of the border,
    //       see use-day-view-parser.ts to adjust
    //       heightPixels in attendances too
    /* stylelint-disable-next-line order/order */ // TODO: Fix this
    $_border-definition: 1px solid colors.$color_calendar--cell-border;
    border-left: $_border-definition;
    border-bottom: $_border-definition;
  }
  .calendar__slot {
    height: 100px;
  }

  .calendar__cell--left {
    border-left: none;
  }
  .calendar__cell--top {
    background-color: colors.$color_box--background;

    &.calendar__cell--left {
      border-top-left-radius: dimensions.$dimension_box--calendar-radius;
    }
    &.calendar__cell--right {
      border-top-right-radius: dimensions.$dimension_box--calendar-radius;
    }
  }

  .calendar__cell-bottom {
    border-bottom: none;
  }
  .calendar__time-label {
    text-align: center;

    color: colors.$color_calendar--label;

    font-size: 14px;
  }
  .calendar__person {
    text-align: center;

    @if features.$feature_calendar-person--uppercase {
      text-transform: uppercase;
    }

    color: colors.$color_calendar--label;

    font-weight: bold;

    padding: 14px 0;
  }
  .calendar__peak-cell {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;

    color: colors.$color_calendar--label;
  }
  .calendar__cell--stuck {
    // if a header cell gets stuck at the top,
    // bring it to foreground
    z-index: 10;
    background-color: colors.$color_box--background;
  }

  .calendar__note__cell {
    font-size: 12px;
    text-align: center;
    color: colors.$color_calendar--label;
  }

  .calendar__note__cell__all-day {
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar__note-container {
    padding: 4px 0;
    display: flex;
    align-items: center;
  }

  .calendar__note {
    border-radius: 8px;
    padding: 2px 0;
    position: absolute;
  }

  .calendar__note__color-red {
    background-color: colors.$color_red-note--background;
    color: colors.$color_red-note--text;
  }

  .calendar__note__color-green {
    background-color: colors.$color_green-note--background;
    color: colors.$color_green-note--text;
  }

  .calendar__note__starts-before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .calendar__note__ends-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .hidden {
    visibility: hidden;
  }
</style>
