<script lang="ts" setup>
  import { onMounted, PropType, ref, watch } from "vue";
  import { getYear } from "date-fns";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";
  import { Holiday } from "frontend/interfaces/settings/holiday";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { useStatesFormSelect } from "frontend/uses/use-form-select";
  import { removeIfExists } from "shared/utils/array-utils";
  import { requestHolidays } from "frontend/api/application/settings/request-holidays";
  import { parseHoliday } from "frontend/parser/settings/parse-holiday";

  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  defineProps({
    ...useAsModalChildProps(),
    selectedHolidaysIds: {
      type: Array as PropType<Array<PrimaryKey>>,
      default: () => [],
    },
  });

  const emit = defineEmits(["update:selectedHolidaysIds"]);

  const allHolidays = ref<Array<Holiday>>([]);
  const holidays = ref<Array<Holiday>>([]);
  const selectedStateId = ref<PrimaryKey | null>(null);
  const selectedIds = ref<Array<PrimaryKey>>([]);
  const selectAll = ref<boolean>(false);

  const { options } = useStatesFormSelect();

  const loadAllHolidays = async () => {
    const data = await requestHolidays();
    if (data.entities) {
      allHolidays.value = data.entities.map(parseHoliday);
      holidays.value = data.entities.map(parseHoliday);
    }
  };

  onMounted(loadAllHolidays);

  const onStateSelected = () => {
    selectAll.value = false;
    selectedIds.value = [];
    holidays.value = allHolidays.value.filter((holiday) =>
      holiday.states.some((state) => state === selectedStateId.value)
    );
  };

  const onCheckboxClicked = (id: PrimaryKey) => {
    selectAll.value = false;
    if (selectedIds.value.includes(id)) {
      removeIfExists(selectedIds.value, id);
    } else selectedIds.value.push(id);
  };

  const onSelectAll = () => {
    selectAll.value = !selectAll.value;
    if (selectAll.value) selectedIds.value = holidays.value.map((h) => h.id);
    else selectedIds.value = [];
  };

  watch(selectedIds, () => {
    emit("update:selectedHolidaysIds", selectedIds.value);
  });

  const isFirstInYear = (holiday: Holiday) => {
    const position = holidays.value.indexOf(holiday);
    if (position === 0) return true;
    else
      return (
        getYear(holidays.value[position - 1].date) !== getYear(holiday.date)
      );
  };
</script>

<template>
  <div v-bind:class="{ 'holiday-selection--as-modal': asModalChild }">
    <FormSelect
      v-model="selectedStateId"
      label="Bundesland"
      v-bind:options="options"
      v-on:update:model-value="onStateSelected"
    />
    <div class="holidays__wrapper">
      <div class="holidays__row holidays--header">
        <div class="holidays__first-cell"></div>
        <div class="holidays__cell">Feiertag</div>
        <div class="holidays__cell">Datum</div>
        <div class="holidays__cell">Kommentar</div>
        <div class="holidays__last-cell">
          <FormCheckbox
            label="Alle"
            v-bind:model-value="selectAll"
            v-on:update:model-value="onSelectAll()"
          />
        </div>
      </div>
      <div
        v-for="holiday in holidays"
        v-bind:key="holiday.id"
        class="holidays__row"
        v-bind:class="{ 'holidays-new-year': isFirstInYear(holiday) }"
      >
        <div class="holidays__first-cell">
          <div v-if="isFirstInYear(holiday)">{{ getYear(holiday.date) }}</div>
        </div>
        <div class="holidays__cell">{{ holiday.name }}</div>
        <div class="holidays__cell">
          {{ formatDate(holiday.date, DateFormat.DateOnlyLong) }}
        </div>
        <div class="holidays__cell">{{ holiday.comment }}</div>
        <div class="holidays__last-cell">
          <FormCheckbox
            v-bind:model-value="selectedIds.includes(holiday.id)"
            v-on:update:model-value="onCheckboxClicked(holiday.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .holiday-selection--as-modal {
    width: 80vw;
    min-height: 80vh;
  }

  .holidays__wrapper {
    margin: 50px 0;
    height: 60vh;
    overflow-y: auto;
    border: solid 1px colors.$color_base-list--separator;
  }

  .holidays__row {
    display: flex;
    flex-flow: row wrap;
    border: solid 1px colors.$color_base-list--separator;
  }

  .holidays--header {
    position: sticky;
    top: 0;
    background-color: colors.$color_holidays-table-header--background;
  }

  .holidays__cell {
    flex: 1;
    padding: 8px;
  }

  .holidays__first-cell,
  .holidays__last-cell {
    width: 80px;
    padding: 8px;
  }

  .holidays__first-cell {
    border-right: solid 1px colors.$color_base-list--separator;
  }

  .holidays-new-year {
    border-top: 2px solid colors.$color_inline-editable__outline;
  }
</style>
