<script lang="ts" setup>
  import { PropType } from "vue";

  import {
    Calendar,
    CalendarCell,
    CalendarEntry,
    CalendarRow,
    CalendarUtils,
  } from "frontend/interfaces/calendar";
  import {
    conditionalContextMenuEntry,
    useContextMenu,
  } from "shared/utils/context-menu/use-context-menu";
  import {
    stylesForEntry,
    utilsForEntry,
  } from "frontend/utils/base-calendar-utils";
  import { ResizingPosition } from "frontend/interfaces/resizing";
  import { PrimaryKey } from "frontend/interfaces/primary-key";

  import BaseResizer, {
    OnResizing,
  } from "frontend/components/base/BaseResizer.vue";
  import NowIndicator from "frontend/components/base-calendar/NowIndicator.vue";

  const props = defineProps({
    calendar: {
      type: Object as PropType<Calendar>,
      default: null,
    },
    row: {
      type: Object as PropType<CalendarRow>,
      default: null,
    },
    cell: {
      type: Object as PropType<CalendarCell>,
      required: true,
    },
    enableResize: {
      type: Boolean,
      default: false,
    },
    enableDragAndDrop: {
      type: Boolean,
      default: false,
    },
    initialHighlight: {
      type: String,
      default: null,
    },
    currentHover: {
      type: String as PropType<string | null>,
      default: null,
    },
    enableNowIndicator: {
      // TODO: only possible if the calendar is profiled
      // otherwise it will not work
      type: Boolean,
      default: false,
    },
  });

  defineEmits([
    "drag-start",
    "drag-end",
    "cell-click",
    "cell-drop",
    "cell-ctx",
    "entry",
    "refresh-needed",
    "hover",
  ]);

  // ------------------------------------------------
  // Hover
  // ------------------------------------------------
  const isHovered = (entry: { hoverOn?: PrimaryKey }): boolean =>
    !!entry.hoverOn &&
    (entry.hoverOn === props.currentHover ||
      entry.hoverOn === props.initialHighlight);

  // ------------------------------------------------
  // Resizing
  // ------------------------------------------------
  const onEntryResizing = (
    entry: CalendarEntry,
    utils: CalendarUtils,
    position: ResizingPosition,
    offset: number,
    stopped: boolean
  ) => {
    if (!props.enableResize) return; // resizing has to be enabled
    if (!entry.onResize) return; // we only want to transmit it to entries that want to receive it

    entry.onResize(
      entry,
      position,
      offset,
      stopped,
      utils.relativeDateToEntry(offset)
    );
  };
  // ---------------------------------------------------------------------------
  // Setup context-menu
  // ---------------------------------------------------------------------------
  const ctx = useContextMenu();
  if (props.cell.ctxPasteAppointment)
    conditionalContextMenuEntry(ctx, props.cell.ctxPasteAppointment);
  if (props.cell.ctxPasteAttendance)
    conditionalContextMenuEntry(ctx, props.cell.ctxPasteAttendance);
  // ---------------------------------------------------------------------------
</script>

<template>
  <div
    class="calendar__cell calendar__slot"
    v-on:click="$emit('cell-click', $event, cell)"
    v-on:dragenter.prevent
    v-on:dragover.prevent
    v-on:drop="$emit('cell-drop', $event, cell)"
    v-on:contextmenu="$emit('cell-ctx', $event, cell, ctx)"
  >
    <div
      v-for="layer in cell.layers"
      v-bind:key="layer.key"
      class="calendar__layer"
    >
      <template v-for="entry in layer.entries" v-bind:key="entry.key">
        <component
          v-bind:is="enableResize && !!entry.onResize ? BaseResizer : 'div'"
          class="calendar__entry"
          v-bind:style="
            stylesForEntry(entry, layer, {
              isHovered: isHovered(entry),
            })
          "
          v-bind:draggable="enableDragAndDrop && !!entry.onDrop"
          v-on:click="
            (event: Event) =>
              entry.onClick
                ? entry.onClick(entry, event)
                : $emit('entry', entry)
          "
          v-on:mouseover="$emit('hover', entry.hoverOn)"
          v-on:mouseleave="$emit('hover')"
          v-on:dragstart="$emit('drag-start', $event, entry)"
          v-on:dragend="$emit('drag-end', $event, entry)"
          v-on:resizing="
            ((position, offset, stopped) =>
              calendar
                ? onEntryResizing(
                    entry,
                    utilsForEntry(entry, layer, cell, row, calendar),
                    position,
                    offset,
                    stopped
                  )
                : undefined) as OnResizing
          "
        >
          <component
            v-bind:is="entry.componentCalendar()"
            class="calendar__entry__bc-component"
            v-bind:entry="entry"
            v-bind:utils="utilsForEntry(entry, layer, cell, row, calendar)"
            v-bind:data-hover-on="entry.hoverOn"
            v-bind:is-hovered="isHovered(entry)"
            v-on:refresh-needed="$emit('refresh-needed')"
          />
        </component>
      </template>
    </div>
    <NowIndicator
      v-if="enableNowIndicator"
      v-bind:row="row"
      v-bind:cell="cell"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "sass:color";

  .calendar__cell {
    height: 100%;

    // positioning inside
    position: relative;

    // NOTE: If you change the width of the border,
    //       see use-day-view-parser.ts to adjust
    //       heightPixels in attendances too
    /* stylelint-disable-next-line order/order */ // TODO: Fix this
    $_border-definition: 1px solid colors.$color_calendar--cell-border;
    border-left: $_border-definition;
    border-bottom: $_border-definition;
  }
  .calendar__slot {
    height: 100px;
  }

  .calendar__entry {
    position: absolute;
    left: 0;
    right: 0;
  }

  .calendar__entry__mock {
    padding: 5px;
    font-size: 12px;
    color: colors.$color_text--global;
  }

  .calendar__entry__bc-component {
    height: 100%;
  }
</style>
