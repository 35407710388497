<script lang="ts">
  import { computed, defineComponent, PropType, toRefs } from "vue";
  import {
    isWithinInterval,
    isSameMinute,
    differenceInMinutes,
  } from "date-fns";

  import { CalendarCell, CalendarRow } from "frontend/interfaces/calendar";
  import { DateFormat, formatDate, mergeDates } from "shared/utils/date-utils";
  import { ConstantsDayView } from "frontend/utils/constants";
  import { useRecurringTime } from "frontend/uses/use-recurring";
  import { profiledCalendarConstants } from "frontend/utils/profile-helper";

  export default defineComponent({
    props: {
      row: {
        type: Object as PropType<CalendarRow>,
        default: null,
      },
      cell: {
        type: Object as PropType<CalendarCell>,
        default: null,
      },
    },
    setup(props) {
      const { row, cell } = toRefs(props);
      const { currentTime } = useRecurringTime();

      const currentTimeLabel = computed(() =>
        formatDate(currentTime.value, DateFormat.TimeOnlyWithoutSeconds)
      );

      const isCurrentRow = computed(
        () =>
          !!row.value.cells.find(
            (cell) =>
              isWithinInterval(currentTime.value, {
                start: cell.interval.from,
                end: cell.interval.to,
              }) && !isSameMinute(currentTime.value, cell.interval.to)
          )
      );

      const isFirstCell = computed(
        () => row.value.cells.indexOf(cell.value) === 0
      );

      const styleOffset = computed(() => {
        const diffMinutes = differenceInMinutes(
          currentTime.value,
          mergeDates(currentTime.value, row.value.cells[0]?.interval.from)
        );
        const offset =
          (diffMinutes * 1.0) /
          (profiledCalendarConstants(ConstantsDayView).TIMESCALE_STEP_SIZE *
            1.0);
        return {
          top: `${Math.floor(offset * 100)}%`,
        };
      });

      return {
        currentTimeLabel,
        isCurrentRow,
        isFirstCell,
        styleOffset,
      };
    },
  });
</script>

<template>
  <div
    v-if="isCurrentRow"
    class="now-indicator"
    v-bind:title="currentTimeLabel"
  >
    <div class="now-indicator__line" v-bind:style="styleOffset"></div>

    <template v-if="isFirstCell">
      <div class="now-indicator__label" v-bind:style="styleOffset">
        {{ currentTimeLabel }}
      </div>
      <div class="now-indicator__circle" v-bind:style="styleOffset">
        &#11044;
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .now-indicator > div {
    position: absolute;
    color: colors.$color-now-indicator--text;
  }

  .now-indicator__label {
    font-size: 14px;
    left: -54px;
  }

  .now-indicator__line {
    width: calc(100% + 1px);
    border-top: 1px solid;
    margin-left: -1px;
    margin-top: -1px;
    z-index: 4;
  }

  .now-indicator__circle {
    font-size: 10px;
    margin-left: -6px;
    margin-top: -6px;
    z-index: 4;
  }
</style>
