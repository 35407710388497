<script lang="ts">
  import {
    defineComponent,
    onMounted,
    PropType,
    computed,
    reactive,
    ref,
    toRefs,
    unref,
    watch,
  } from "vue";

  import { useThrottledWatcher } from "frontend/uses/use-throttled-watcher";
  import { usePending } from "frontend/uses/use-pending";
  import {
    createPatient,
    requestPatients,
  } from "frontend/api/application/request-patients";
  import {
    httpPayloadPatient,
    newPatient,
    parsePatient,
  } from "frontend/parser/parse-patient";
  import { GdtPatient, Patient } from "frontend/interfaces/patient";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { log, LogLevel } from "shared/utils/logger";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
  import { PrimaryKey } from "frontend/interfaces/primary-key";

  import FormInput from "frontend/components/form/FormInput.vue";
  import MutatedEditPatient from "frontend/components/mutated/MutatedEditPatient.vue";

  export default defineComponent({
    components: { FormInput },
    props: {
      hidePatientCreation: {
        type: Boolean,
        default: false,
      },
      importedPatient: {
        type: Object as PropType<GdtPatient | null>,
        default: null,
      },
      searchQuery: {
        type: String as PropType<string | null>,
        default: null,
      },
      excludedPatients: {
        type: Array as PropType<Array<PrimaryKey>>,
        default: () => [],
      },
    },
    emits: ["patient-selected"],
    setup(props, { emit }) {
      const currentSearch = ref<string>("");
      const patients = ref<Array<Patient>>([]);
      const currentQuery = ref<string>("");

      const { isPending, doWithPending } = usePending();
      const { subscribe } = useThrottledWatcher(currentSearch, 500);
      const loadPatients = async (query: string | null) => {
        await doWithPending(async () => {
          if (query) currentQuery.value = query;
          else currentQuery.value = "";
          let data = null;
          if (query) data = await requestPatients(buildQuery(query));
          else data = await requestPatients();
          if (data.patients) {
            patients.value = data.patients.map(parsePatient);
          } else patients.value = [];
        });
      };
      subscribe(async () => {
        await loadPatients(unref(currentSearch));
      });
      onMounted(() => loadPatients(null));

      // ---------------------------------------------------------------------
      // New Patient Dialog
      // ---------------------------------------------------------------------
      const { custom: customModal } = useSimpleModal();
      const doCreatePatient = () => {
        const componentProps = reactive({
          patient: newPatient(props.importedPatient),
          errors: {} as Partial<Record<string, string>>,
          visualModal: true,
        });

        customModal(MutatedEditPatient, {
          noAutoClose: true,
          hideCancel: false,
          hideConfirm: false,
          confirmLabel: "Patient anlegen",
          componentProps,
          onClose: async (confirm, close) => {
            if (confirm) {
              const result = await createPatient(
                httpPayloadPatient(componentProps.patient)
              );

              if (result.errors && Object.keys(result.errors).length > 0) {
                componentProps.errors = result.errors;
              } else if (!result.success) {
                log(
                  LogLevel.Error,
                  "could not save patient",
                  componentProps.patient
                );
              } else {
                emit("patient-selected", parsePatient(result.patient));
                close();
              }
            } else {
              close();
            }
          },
        });
      };

      const processedPatients = computed(() =>
        patients.value.filter(
          (patient) => props.excludedPatients.indexOf(patient.id) < 0
        )
      );

      watch(
        toRefs(props).searchQuery,
        () => (currentSearch.value = props.searchQuery ?? ""),
        { immediate: true }
      );

      return {
        currentSearch,
        currentQuery,
        processedPatients,
        isPending,

        doCreatePatient,

        // libs
        DateFormat,
        formatDate,
      };
    },
  });

  function buildQuery(rawQuery: string): string {
    return `${rawQuery}`;
  }
</script>

<template>
  <div class="vue-component__patient-search">
    <FormInput v-model="currentSearch" label="Suchbegriff" />
    <div v-if="currentQuery || processedPatients.length > 0">
      <p v-if="currentQuery">
        {{ processedPatients.length }} Suchergebnisse für "{{ currentQuery }}"
        <span v-if="isPending">... lade ...</span>
      </p>
      <div class="patient__wrapper">
        <div class="patient patient--header">
          <div class="patient__info icon"></div>
          <div class="patient__info">Name</div>
          <div class="patient__info">Versichertennummer</div>
          <div class="patient__info">Krankenkasse</div>
          <div class="patient__info">Geburtsdatum</div>
        </div>
        <div
          v-for="patient in processedPatients"
          v-bind:key="patient.id"
          class="patient"
          v-on:click="$emit('patient-selected', patient)"
        >
          <div class="patient__info icon">
            <i
              v-if="patient.is_praxisapp"
              v-tooltip="'Nutzer hat PraxisApp'"
              class="fe fe-smartphone icon--has-praxisapp"
            ></i>
          </div>
          <div class="patient__info">
            {{ patient.first_name }} {{ patient.name }}
          </div>
          <div class="patient__info">{{ patient.kvnr }}</div>
          <div class="patient__info">{{ patient.insurance_name }}</div>
          <div class="patient__info">
            {{ formatDate(patient.date_of_birth, DateFormat.DateOnly) }}
          </div>
        </div>
        <div
          v-if="!hidePatientCreation"
          class="patient"
          v-on:click="doCreatePatient"
        >
          <strong>Neuen Patienten anlegen</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .patient__wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .patient {
    display: flex;
    justify-content: space-between;

    cursor: pointer;
    padding: 8px;
    background-color: colors.$color_patient-search__patient--background;
    border-bottom: 1px colors.$color_patient-search__patient--border solid;
  }

  .patient--header,
  .patient:hover {
    background-color: colors.$color_table-like__header--background;
    color: colors.$color_table-like__header--text;
  }

  .patient__info {
    flex: 1;
  }

  .icon {
    flex: 0 1 19px;
    margin-right: 10px;
  }
</style>
