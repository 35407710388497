<script lang="ts" setup>
  import { computed, CSSProperties } from "vue";

  const props = defineProps({
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    editAction: {
      type: Boolean,
      default: false,
    },
    deleteAction: {
      type: Boolean,
      default: false,
    },
    duplicateAction: {
      type: Boolean,
      default: false,
    },
    calendarAction: {
      type: Boolean,
      default: false,
    },
    chevron: {
      type: Boolean,
      default: false,
    },
    flush: {
      type: Boolean,
      default: false,
    },
    visualSmall: {
      type: Boolean,
      default: false,
    },
    enableColor: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
  });

  defineEmits<{
    "edit-item": [];
    "delete-item": [];
    "show-item": [];
    "duplicate-item": [];
    select: [];
    hover: [hover: boolean];
  }>();
  //style
  const colorStyle = computed<CSSProperties | undefined>(() => {
    const color = props.color ?? "#000000";
    return {
      borderLeft: `7px solid ${color}`,
      marginRight: "8px",
    };
  });
</script>

<template>
  <div
    class="list-item"
    v-bind:class="{
      'list-item__select': chevron,
      'list-item__flush': flush,
      'list-item--small': visualSmall,
    }"
    v-on:click="$emit('select')"
    v-on:mouseenter="$emit('hover', true)"
    v-on:mouseleave="$emit('hover', false)"
  >
    <div v-if="enableColor" v-bind:style="colorStyle"></div>
    <div>
      <h5 v-if="title" class="list-item__title">{{ title }}</h5>
      <div class="list-item__description"><slot name="description"></slot></div>
      <div v-if="subtitle" class="list-item__subtitle">{{ subtitle }}</div>
      <div v-if="text" class="list-item__text">{{ text }}</div>
    </div>

    <div class="list-item__icons">
      <slot name="icons"></slot>
      <i
        v-if="editAction"
        title="Bearbeiten"
        class="fe fe-pencil list-item__icon"
        v-on:click.stop="$emit('edit-item')"
      ></i>
      <i
        v-if="deleteAction"
        title="Löschen"
        class="fe fe-trash list-item__icon"
        v-on:click.stop="$emit('delete-item')"
      ></i>
      <i
        v-if="calendarAction"
        title="im Kalender anzeigen"
        class="fe fe-calendar-day list-item__icon"
        v-on:click.stop="$emit('show-item')"
      ></i>
      <i
        v-if="duplicateAction"
        title="Duplizieren"
        class="fe fe-stack list-item__icon"
        v-on:click.stop="$emit('duplicate-item')"
      ></i>
      <i
        v-if="chevron"
        class="fe fe-arrow-right list-item__link"
        v-on:click.stop="$emit('select')"
      ></i>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  .list-item {
    display: flex;
    padding: 15px;
    margin: 7px;
    border: 1px solid colors.$color_base-list--separator;
    border-radius: 5px;

    &:hover {
      background-color: colors.$color_base-list__hover--background;
    }

    &__select {
      cursor: pointer;
    }

    &__flush {
      margin: 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid colors.$color_base-list--separator;
    }

    &__title {
      margin: 0;
      display: inline;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__description {
      display: inline;
      font-size: 14px;
    }

    &__text {
      color: colors.$color_base-list__light--text;
      margin-top: 2px;
      font-size: 12px;
    }

    &__icons {
      margin-left: auto;
      align-self: center;
      cursor: pointer;
    }

    &__icon {
      margin: 0 5px;
      padding: 4px 7px;
      border-radius: 4px;
      border: 1px solid colors.$color_base-list--separator;

      &:hover {
        color: colors.$color_main-area--background;
      }
    }

    &__link {
      font-weight: bold;
      margin: 0 10px;
    }
  }

  .list-item--small {
    padding: 5px;
  }
</style>
