<script lang="ts">
  import { defineComponent, onMounted, PropType, ref, toRefs } from "vue";
  import { isSameDay } from "date-fns";

  import { today } from "shared/utils/date-utils";
  import { useMiniCalendar } from "frontend/uses/use-mini-calendar";
  import {
    MiniCalendarDay,
    MiniCalendarDayVisual,
    MiniCalendarWeek,
  } from "frontend/interfaces/mini-calendar";
  import { log, LogLevel } from "shared/utils/logger";
  import { Holiday } from "frontend/interfaces/settings/holiday";
  import { requestHolidays } from "frontend/api/application/settings/request-holidays";
  import { parseHoliday } from "frontend/parser/settings/parse-holiday";

  export default defineComponent({
    props: {
      month: {
        // shows the month view of the given date (time and day of the month don't matter)
        type: Date as PropType<Date>,
        default: () => new Date(),
      },
      highlightedDays: {
        type: Array as PropType<Array<Date>>,
        default: () => [],
      },
      selectedDays: {
        type: Array as PropType<Array<Date>>,
        default: () => [],
      },
    },
    emits: ["dateSelected"],
    setup(props, { emit }) {
      const { month, highlightedDays, selectedDays } = toRefs(props);

      const { calendar } = useMiniCalendar(
        month,
        highlightedDays,
        selectedDays
      );

      const onDateSelected = (
        day: MiniCalendarDay,
        week: MiniCalendarWeek
      ): void => {
        log(LogLevel.Info, "[MINI-CAL] Date was selected", day, week);
        emit("dateSelected", day, week);
      };

      const holidays = ref<Array<Holiday>>([]);
      const loadHolidays = async () => {
        const data = await requestHolidays("false");
        if (data.entities) holidays.value = data.entities.map(parseHoliday);
      };
      onMounted(loadHolidays);

      const isHoliday = (date: Date) =>
        holidays.value.some(
          (holiday) => holiday.date.getTime() === date.getTime()
        );

      return {
        calendar,
        MiniCalendarDayVisual,
        onDateSelected,
        isHoliday,
        isSameDay,
        today,
      };
    },
  });
</script>

<template>
  <div class="vue-component__mini-calendar">
    <div class="mini-calendar__headers">
      <div
        v-for="(header, index) in calendar.headers"
        v-bind:key="index"
        class="mini-calendar__header"
      >
        {{ header }}
      </div>
    </div>
    <div
      v-for="week in calendar.weeks"
      v-bind:key="week.key"
      class="mini-calendar__week"
    >
      <div
        v-for="day in week.days"
        v-bind:key="day.key"
        class="mini-calendar__day"
        v-bind:class="{
          'mini-calendar__day--selected':
            day.visual === MiniCalendarDayVisual.Selected,
          'mini-calendar__day--light':
            day.visual === MiniCalendarDayVisual.Light,
          'mini-calendar__day--highlighted': day.highlighted,
          'mini-calendar__day--holiday': isHoliday(day.date),
          'mini-calendar__day--today': isSameDay(day.date, today()),
        }"
        v-on:click="onDateSelected(day, week)"
      >
        {{ day.label }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/mini-calendar";

  .mini-calendar__week,
  .mini-calendar__headers {
    display: flex;
  }

  .mini-calendar__week {
    margin-bottom: 5px;
  }

  .mini-calendar__day,
  .mini-calendar__header {
    @include mini-calendar.cell;
  }

  .mini-calendar__day {
    @include mini-calendar.day;
  }

  .mini-calendar__day--light {
    background-color: colors.$color_mini-calendar-day__light--background;
    color: colors.$color_mini-calendar-day__light--text;

    &:hover {
      background-color: color.adjust(
        colors.$color_mini-calendar-day__light--background,
        $lightness: -5%
      );
    }
  }

  .mini-calendar__day--highlighted {
    @include mini-calendar.day--highlighted;
  }

  .mini-calendar__day--selected {
    @include mini-calendar.day--selected;
  }

  .mini-calendar__day--holiday {
    border: 2px solid colors.$color_red-note--text;
  }

  .mini-calendar__day--today {
    color: colors.$color_mini-calendar-day__today;
  }
</style>
