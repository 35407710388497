<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { StatusValue } from "frontend/utils/animation-status";

  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";

  export default defineComponent({
    components: { BaseSpinner },
    props: {
      animationStatus: {
        type: Number as PropType<StatusValue>,
        default: 0,
      },
      visualSmall: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return {
        // libs
        StatusValue,
      };
    },
  });
</script>

<template>
  <div
    class="animation-status"
    v-bind:class="{
      'animation-status--error': animationStatus === StatusValue.Failure,
      'animation-status--success': animationStatus === StatusValue.Success,
      'animation-status--visual-small': visualSmall,
    }"
  >
    <i
      v-if="animationStatus === StatusValue.Success"
      class="fe fe-checkmark--with-circle animation-status__animation"
    >
    </i>
    <i
      v-if="animationStatus === StatusValue.Failure"
      class="fe fe-close--with-circle animation-status__animation"
    ></i>
    <BaseSpinner
      v-if="animationStatus === StatusValue.Pending"
      class="animation-status__spinner"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  @keyframes animation-status {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .animation-status {
    font-size: 20px;

    display: inline-block;

    min-height: 25px;
  }

  .animation-status--visual-small {
    font-size: 12px;
    min-height: 12px;

    .animation-status__spinner {
      transform: scale(0.55);
      height: 12px;
    }
  }

  .animation-status--success {
    color: colors.$color_animation-status--success--text;
  }
  .animation-status--error {
    color: colors.$color_animation-status--error--text;
  }

  .animation-status__animation {
    animation-name: animation-status;
    animation-duration: 0.5s;
  }
</style>
