<script lang="ts" setup>
  import {
    computed,
    PropType,
    ref,
    CSSProperties,
    onMounted,
    toRefs,
  } from "vue";
  import {
    endOfDay,
    startOfDay,
    differenceInMinutes,
    addMinutes,
    isAfter,
    isSameDay,
  } from "date-fns";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { TmpUnit } from "frontend/interfaces/unit";
  import { Color } from "frontend/interfaces/color";
  import { Cache } from "frontend/utils/request-cache";
  import { PrimaryKey } from "frontend/interfaces/primary-key";

  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import FormText from "frontend/components/form/FormText.vue";
  import InlineEditable from "frontend/components/InlineEditable.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormSelectOfficeRoom from "frontend/components/form/FormSelectOfficeRoom.vue";
  import FormSelectOfficePerson from "frontend/components/form/FormSelectOfficePerson.vue";
  import FormOfficeColor from "frontend/components/form/FormOfficeColor.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import BaseAccordion from "frontend/components/base/BaseAccordion.vue";

  const props = defineProps({
    unit: {
      type: Object as PropType<TmpUnit>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    // if true, the from date can be set to any date, otherwise the date will be restricted
    disableLimitOnFrom: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["new-person", "new-date", "update-intervals"]);

  const { unit } = toRefs(props);

  const limitDates = computed(() => ({
    minDate: !props.disableLimitOnFrom
      ? startOfDay(unit.value.from)
      : undefined,
    maxDate: endOfDay(unit.value.to),
    maxDateFrom: !props.disableLimitOnFrom
      ? addMinutes(endOfDay(unit.value.to), -currentDurationInMinutes.value)
      : undefined,
  }));

  const currentDurationInMinutes = computed(() =>
    differenceInMinutes(unit.value.to, unit.value.from)
  );

  const doAddMinutesToUnit = (amount: number) => {
    const newTo = addMinutes(unit.value.to, amount);
    if (isAfter(newTo, unit.value.from) && isSameDay(newTo, unit.value.from)) {
      unit.value.to = newTo;
    }
    changeDate();
  };
  const doMoveUnit = (newDate: Date | null) => {
    if (!newDate) return;
    unit.value.to = addMinutes(newDate, currentDurationInMinutes.value);
    unit.value.from = newDate;
    changeDate();
  };

  const onNewDuration = (durationInMinutes: number) => {
    if (durationInMinutes > 0) {
      const newTo = addMinutes(unit.value.from, durationInMinutes);
      if (isSameDay(newTo, unit.value.from)) unit.value.to = newTo;
    }
    changeDate();
  };

  const onNewPersonID = (newPersonId: PrimaryKey) => {
    if (props.index === 0) emit("new-person", newPersonId);
  };

  const changeDate = () => {
    emit("update-intervals", props.index);
    if (props.index === 0) emit("new-date", unit.value);
  };

  const accordionIsOpen = ref<boolean>(true);

  //color
  const availableColors = ref<Array<Color>>([]);
  const loadColors = async () => {
    availableColors.value = await Cache.getCachedColors();
  };
  onMounted(loadColors);
  const currentColor = computed<Color | null>(() => {
    for (const color of availableColors.value) {
      if (color.id === unit.value.color_id) return color;
    }
    return null;
  });
  //style
  const styles = computed<CSSProperties | undefined>(() => {
    const color = currentColor.value ? currentColor.value.value : "#000000";
    return {
      borderLeft: `7px solid ${color}`,
    };
  });
</script>

<template>
  <BaseAccordion v-model="accordionIsOpen">
    <template v-slot:header>
      <div
        v-if="!accordionIsOpen"
        class="popup-appointment__header"
        v-bind:style="styles"
      >
        <div class="popup-appointment__header__content">
          <strong>
            {{ formatDate(unit.from, DateFormat.TimeOnlyWithoutSeconds) }} -
            {{ formatDate(unit.to, DateFormat.TimeOnlyWithoutSeconds) }}
          </strong>
          <div>Name: {{ unit.name }}</div>
        </div>
      </div>
    </template>
    <FormInput v-model="unit.name" label="Name" visual-small />
    <FormDatetime
      v-bind:model-value="unit.from"
      enable-time
      visual-small
      v-bind:allow-deletion="false"
      v-bind:max-date="limitDates.maxDateFrom"
      v-bind:min-date="limitDates.minDate"
      label="Startzeit"
      v-on:update:model-value="doMoveUnit"
    />

    <FormDatetime
      v-model="unit.to"
      enable-time
      visual-small
      v-bind:allow-deletion="false"
      v-bind:max-date="limitDates.maxDate"
      v-bind:min-date="unit.from"
      label="Endzeit"
      v-on:update:model-value="changeDate"
    />

    <FormText label="Dauer (in Minuten)">
      <div class="popup-appointment__unit-duration">
        <BaseButton
          class="popup-appointment__unit-duration__button-minus"
          visual-small
          v-on:click="doAddMinutesToUnit(-5)"
          >&hyphen;&nbsp;5</BaseButton
        >
        <span>
          <InlineEditable
            v-bind:display-value="
              differenceInMinutes(unit.to, unit.from).toString()
            "
            v-on:value-edited="
              (duration) => onNewDuration(parseInt(duration, 10))
            "
          />
        </span>
        <BaseButton
          class="popup-appointment__unit-duration__button-plus"
          visual-small
          v-on:submit="doAddMinutesToUnit(5)"
          >&plus;&nbsp;5</BaseButton
        >
      </div>
    </FormText>

    <FormOfficeColor v-model="unit.color_id" label="Farbe" allow-blank />

    <FormSelectOfficePerson
      v-model="unit.person_id"
      label="Mitarbeiter"
      visual-small
      v-bind:include-blank="index !== 0"
      v-on:update:model-value="onNewPersonID"
    />

    <FormSelectOfficeRoom
      v-model="unit.room_id"
      label="Raum (optional)"
      visual-small
    />
  </BaseAccordion>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .popup-appointment__unit-duration {
    margin-top: 5px;

    user-select: none;

    display: flex;
    justify-content: center;
  }

  .popup-appointment__unit-duration__button-minus {
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup-appointment__unit-duration__button-plus {
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup-appointment__header {
    min-width: 180px;
  }

  .popup-appointment__header__content {
    margin-left: 8px;
  }

  :deep(.form-input__input) {
    border: 1px solid colors.$color_input--border;
    font-size: 12px;
  }

  :deep(.vue-component__form-input) {
    padding: 0;
  }

  :deep(.form-input__label) {
    padding: 0;
  }

  :deep(.form-select__select) {
    width: 200px;
  }
</style>
