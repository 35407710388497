<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { GdtPatient, Patient } from "frontend/interfaces/patient";

  import FormText from "frontend/components/form/FormText.vue";

  export default defineComponent({
    components: { FormText },
    props: {
      patient: {
        type: Object as PropType<Patient | GdtPatient>,
        required: true,
      },
    },
    setup() {
      return {
        // libs
        formatDate,
        DateFormat,
      };
    },
  });
</script>

<template>
  <div>
    <div class="patient-profile">
      <div class="patient-name">
        <strong>{{ patient.first_name }} {{ patient.name }}</strong>
        ({{ patient.human_age }})
      </div>

      <FormText class="patient-info" label="Geburtsdatum">
        {{ formatDate(patient.date_of_birth, DateFormat.DateOnly) }}
      </FormText>
      <FormText class="patient-info" label="Versichertennummer">
        {{ patient.kvnr || "-" }}
      </FormText>
      <FormText class="patient-info" label="Versicherung">
        {{ patient.insurance_name }}
      </FormText>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .patient-profile {
    margin: 20px 0;
    background-color: colors.$color_patient-search__patient--background;
    padding: 10px;
  }

  .patient-name {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .patient-info {
    margin: 5px 0;
  }
</style>
