<script lang="ts" setup>
  import { computed, onUnmounted, ref, unref } from "vue";

  import {
    definePropsEntry,
    defineEmitsEntry,
  } from "frontend/utils/base-calendar-utils";
  import { CalendarEntrySettingsAttendance } from "frontend/interfaces/calendar";
  import { useResponsiveness } from "frontend/uses/use-responsiveness";
  import { useColorLookup } from "frontend/uses/use-color-lookup";
  import { useColorModification } from "frontend/uses/use-color-modificaiton";
  import {
    conditionalContextMenuEntry,
    useContextMenu,
  } from "shared/utils/context-menu/use-context-menu";

  const props =
    defineProps(definePropsEntry<CalendarEntrySettingsAttendance>());
  defineEmits(defineEmitsEntry());

  const labelElement = ref<HTMLElement | null>(null);
  const flip = ref<boolean>(false);
  const { element, doDisconnect } = useResponsiveness(
    (_info: ResizeObserverEntry) => {
      const label = unref(labelElement);
      const parent = unref(element);
      if (!label || !parent) return;

      const parentRect = parent.getBoundingClientRect();
      const labelRect = label.getBoundingClientRect();

      flip.value =
        parentRect.x + parentRect.width < labelRect.x + labelRect.width;
    }
  );
  onUnmounted(doDisconnect);

  const { color } = useColorLookup(
    computed(() => props.entry?.color_id ?? null)
  );
  const { colorRgba } = useColorModification(
    computed(() => color.value?.value ?? null),
    0.8
  );
  // ---------------------------------------------------------------------------
  // Setup context-menu
  // ---------------------------------------------------------------------------
  const ctx = useContextMenu();
  if (props.entry.ctxCopyAttendance)
    conditionalContextMenuEntry(ctx, props.entry.ctxCopyAttendance);
  // ---------------------------------------------------------------------------
</script>

<template>
  <div
    ref="element"
    class="settings-attendance"
    v-bind:class="{ 'settings-attendance--hovered': isHovered }"
    v-bind:style="{
      'background-color': !isHovered && colorRgba ? colorRgba : undefined,
    }"
    v-bind:title="entry.personLabel + ': ' + entry.label"
  >
    <div class="settings-attendance__person">{{ entry.personLabel }}</div>
    <div
      ref="labelElement"
      class="settings-attendance__name"
      v-bind:class="{ 'settings-attendance__name--flip': flip }"
    >
      {{ entry.label }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .settings-attendance {
    display: flex;

    cursor: pointer;

    border: 1px solid colors.$color_calendar__settings-attendance__border;
    background-color: colors.$color_calendar__settings-attendance--background;

    padding: 7px 3px;

    font-size: 14px;

    &:hover,
    &.settings-attendance--hovered {
      background-color: colors.$color_calendar__settings-attendance--background--hover;
    }
  }

  .settings-attendance__person {
    writing-mode: vertical-lr;

    margin-right: 5px;

    font-size: 12px;
    font-weight: bold;
  }

  .settings-attendance__name {
    hyphens: auto;
  }

  .settings-attendance__name--flip {
    writing-mode: vertical-lr;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5;
  }
</style>
