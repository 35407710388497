<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    toRefs,
    watch,
  } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import {
    AppointmentType,
    AppointmentTypeSession,
  } from "frontend/interfaces/settings/appointment-type";
  import { AppointmentTypeConfigs } from "shared/static/enums.ts.erb";
  import {
    AppointmentTypeUnits,
    AppointmentTypeBillingPeriodUnits,
  } from "shared/static/time-units.ts.erb";
  import { Role } from "frontend/interfaces/role";
  import { Cache } from "frontend/utils/request-cache";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { Room } from "frontend/interfaces/room";
  import { StatusValue } from "frontend/utils/animation-status";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
  import { removeIfExists } from "shared/utils/array-utils";
  import { newSession } from "frontend/parser/settings/parse-appointment-type";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormRelativeTime from "frontend/components/form/FormRelativeTime.vue";
  import FormRichedit from "frontend/components/form/FormRichedit.vue";
  import FormCheckboxBitwise from "frontend/components/form/FormCheckboxBitwise.vue";
  import FormOfficeColor from "frontend/components/form/FormOfficeColor.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";
  import CheckboxAccordion from "frontend/components/CheckboxAccordion.vue";
  import BaseAccordion from "frontend/components/base/BaseAccordion.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  export default defineComponent({
    components: {
      FormInput,
      FormCheckbox,
      FormRelativeTime,
      FormRichedit,
      FormCheckboxBitwise,
      FormOfficeColor,
      BaseButton,
      FormCheckboxGroup,
      CheckboxAccordion,
      BaseAccordion,
    },
    props: {
      ...editProps<AppointmentType>(),
    },
    emits: ["entityOpen"],
    setup(props, { emit }) {
      const { entity } = toRefs(props);
      const editComponent = useEditComponent(entity.value);
      const sortedSessions = computed(() => {
        return (editComponent.entityEditable.value.sessions || []).sort(
          (a, b) => a.position - b.position
        );
      });
      ////
      // Roles for checkboxes
      ////
      const roles = ref<Array<Role>>([]);
      const roleOptions = computed(() => {
        return roles.value.map((role) => ({ id: role.id, label: role.name }));
      });
      onMounted(async () => {
        roles.value = await Cache.getCachedRoles();
      });
      const calculateEnabledRoles = (session: AppointmentTypeSession) => {
        const result = [];
        for (const prop in session.roles || {}) {
          const value = session.roles[prop];
          if (value && value.count > 0) result.push(prop);
        }
        return result;
      };
      const onUpdateRoles =
        (session: AppointmentTypeSession) => (selected: PrimaryKey[]) => {
          ensureKey(session, selected);
          for (const prop in session.roles) {
            const value = session.roles[prop];
            if (value /* for typescript */) {
              if (selected.indexOf(prop) >= 0) {
                value.count = Math.max(value.count, 1);
              } else {
                value.count = 0;
              }
            }
          }
        };
      ////
      // Rooms for checkboxes
      ////
      const rooms = ref<Array<Room>>([]);
      const roomOptions = computed(() => {
        return rooms.value.map((room) => ({ id: room.id, label: room.name }));
      });
      onMounted(async () => {
        rooms.value = await Cache.getCachedRooms();
      });
      const { confirm } = useSimpleModal();
      const doDeleteSession = async (session: AppointmentTypeSession) => {
        const result = await confirm(
          "Sind Sie sicher, dass Sie den Einzeltermin löschen möchten?",
          { title: "Einzeltermin löschen" }
        );
        if (result.confirm) {
          removeIfExists(editComponent.entityEditable.value.sessions, session);
          editComponent.onAutosaveFor(`sessions`)();
        }
      };
      const doAddAdditionalSession = () => {
        editComponent.entityEditable.value.sessions.push(
          newSession(editComponent.entityEditable.value)
        );
      };

      const accordionIsOpen = ref<boolean>(
        (entity.value as unknown as { just_created: boolean; unsaved: boolean })
          .unsaved ||
          (
            entity.value as unknown as {
              just_created: boolean;
              unsaved: boolean;
            }
          ).just_created
      );

      watch(accordionIsOpen, () => emit("entityOpen", accordionIsOpen.value));

      return {
        ...editComponent,
        sortedSessions,
        roleOptions,
        calculateEnabledRoles,
        onUpdateRoles,
        roomOptions,
        doDeleteSession,
        doAddAdditionalSession,

        accordionIsOpen,

        // libs
        AppointmentTypeConfigs,
        StatusValue,
        AppointmentTypeUnits,
        AppointmentTypeBillingPeriodUnits,
      };
    },
  });

  function ensureKey(session: AppointmentTypeSession, selected: PrimaryKey[]) {
    if ((session as unknown as { unsaved: boolean }).unsaved) {
      for (const selection of selected) {
        if (Object.keys(session.roles).indexOf(selection) < 0) {
          session.roles[selection] = { role_name: "", count: 0 };
        }
      }
    }
  }
</script>

<template>
  <div class="edit-appointment-type">
    <BaseAccordion v-model="accordionIsOpen">
      <template v-slot:header>
        <h5 class="eat__header">
          {{ entity.name }}
          <span v-if="!accordionIsOpen" class="eat__header-open-text"
            >Termintyp bearbeiten</span
          >
        </h5>
      </template>

      <FormInput
        v-model="entityEditable.name"
        label="Name des Termintyps"
        v-bind:errors="errorFor('name')"
        v-bind:animation-status="statusFor('name')"
        v-on="{ autosave: onAutosaveFor('name') }"
      />
      <FormRichedit
        v-model="entityEditable.description_html"
        label="Beschreibung, die der Patient in der App erhält, wenn er den Termintyp ausgewählt hat"
        v-bind:errors="errorFor('description_html')"
        v-bind:animation-status="statusFor('description_html')"
        v-on="{ autosave: onAutosaveFor('description_html') }"
      />
      <FormInput
        v-model="entityEditable.message"
        label="Text, der bei Buchungsbestätigungen und Erinnerungen an Patienten in der App erscheint"
        type="textarea"
        v-bind:errors="errorFor('message')"
        v-bind:animation-status="statusFor('message')"
        v-on="{ autosave: onAutosaveFor('message') }"
      />
      <FormInput
        v-model="entityEditable.notes"
        label="Praxisinterne Notiz, die bei der Termintypauswahl beim Anlegen des Termins erscheint."
        type="textarea"
        v-bind:errors="errorFor('notes')"
        v-bind:animation-status="statusFor('notes')"
        v-on="{ autosave: onAutosaveFor('notes') }"
      />
      <FormCheckbox
        v-model="entityEditable.allow_message"
        label="dem Patienten bei der Terminbuchung ein Textfeld anzeigen, das ihm ermöglicht Informationen hinzuzufügen"
        v-on="{ autosave: onAutosaveFor('allow_message') }"
      />
      <FormInput
        v-if="entityEditable.allow_message"
        v-model="entityEditable.message_description"
        label="Aufforderung an den Patieten, welche Informationen zum gebuchten Termin mitzuteilen sind."
        type="textarea"
        v-bind:errors="errorFor('message_description')"
        v-bind:animation-status="statusFor('message_description')"
        v-on="{ autosave: onAutosaveFor('message_description') }"
      />
      <div class="eat__time-pickers-line">
        <CheckboxAccordion
          v-bind:label="`Überschreibung der Standard-Praxiseinstellung für 'Terminbuchung frühestens möglich in' (unter 'Allgemein' veränderbar)`"
          v-bind:show="entityEditable.booking_range_begin_number !== null"
          v-on:content:show="entityEditable.booking_range_begin_number = 0"
          v-on:content:hide="entityEditable.booking_range_begin_number = null"
          v-on:content:visibility-change="
            onAutosaveFor('booking_range_begin_number')()
          "
        >
          <FormRelativeTime
            v-model:amount="entityEditable.booking_range_begin_number"
            v-model:unit="entityEditable.booking_range_begin_unit"
            v-bind:time-units="AppointmentTypeUnits"
            label="Terminbuchung frühestens möglich in"
            v-bind:errors="errorFor('booking_range_begin_number')"
            v-bind:animation-status="statusFor('booking_range_begin_number')"
            v-on="{ autosave: onAutosaveFor('booking_range_begin_number') }"
          />
        </CheckboxAccordion>
        <CheckboxAccordion
          v-bind:label="`Überschreibung der Standard-Praxiseinstellung für 'Terminbuchung höchstens möglich bis in' (unter 'Allgemein' veränderbar)`"
          v-bind:show="entityEditable.booking_range_end_number !== null"
          v-on:content:show="entityEditable.booking_range_end_number = 0"
          v-on:content:hide="entityEditable.booking_range_end_number = null"
          v-on:content:visibility-change="
            onAutosaveFor('booking_range_endn_number')()
          "
        >
          <FormRelativeTime
            v-model:amount="entityEditable.booking_range_end_number"
            v-model:unit="entityEditable.booking_range_end_unit"
            v-bind:time-units="AppointmentTypeUnits"
            label="Terminbuchung höchstens möglich bis in"
            v-bind:errors="errorFor('booking_range_end_number')"
            v-bind:animation-status="statusFor('booking_range_end_number')"
            v-on="{ autosave: onAutosaveFor('booking_range_end_number') }"
          />
        </CheckboxAccordion>
      </div>
      <div class="eat__time-pickers-line">
        <FormRelativeTime
          v-model:amount="entityEditable.age_range_begin_number"
          v-model:unit="entityEditable.age_range_begin_unit"
          v-bind:time-units="AppointmentTypeUnits"
          label="Patientalter zum Zeitpunkt des Termins mindestens"
          v-bind:errors="errorFor('age_range_begin_number')"
          v-bind:animation-status="statusFor('age_range_begin_number')"
          v-on="{ autosave: onAutosaveFor('age_range_begin_number') }"
        />
        <FormRelativeTime
          v-model:amount="entityEditable.age_range_end_number"
          v-model:unit="entityEditable.age_range_end_unit"
          v-bind:time-units="AppointmentTypeUnits"
          label="Patientalter zum Zeitpunkt des Termins geringer als"
          v-bind:errors="errorFor('age_range_end_number')"
          v-bind:animation-status="statusFor('age_range_end_number')"
          v-on="{ autosave: onAutosaveFor('age_range_end_number') }"
        />
      </div>
      <CheckboxAccordion
        v-bind:label="'Dieser Termin darf nur ein Mal in einem Gewissen Zeitraum gebucht werden'"
        v-bind:show="entityEditable.billing_perriod_number !== null"
        v-on:content:show="entityEditable.billing_perriod_number = 1"
        v-on:content:hide="entityEditable.billing_perriod_number = null"
        v-on:content:visibility-change="
          onAutosaveFor('billing_perriod_number')()
        "
      >
        <FormRelativeTime
          v-if="entityEditable.billing_perriod_number !== null"
          v-model:amount="entityEditable.billing_perriod_number"
          v-model:unit="entityEditable.billing_perriod_unit"
          label="Maximal eine Buchung Pro Patient in desem Zeitraum"
          v-bind:time-units="AppointmentTypeBillingPeriodUnits"
          v-bind:errors="errorFor('billing_perriod_number')"
          v-bind:animation-status="statusFor('billing_perriod_number')"
          v-on="{ autosave: onAutosaveFor('billing_perriod_number') }"
        />
      </CheckboxAccordion>
      <CheckboxAccordion
        v-bind:label="'Mindestabstand zum nächsten Termin dieses Termintyps'"
        v-bind:show="entityEditable.min_distance_to_self_number !== null"
        v-on:content:show="entityEditable.min_distance_to_self_number = 1"
        v-on:content:hide="entityEditable.min_distance_to_self_number = null"
        v-on:content:visibility-change="
          onAutosaveFor('min_distance_to_self_number')()
        "
      >
        <FormRelativeTime
          v-if="entityEditable.min_distance_to_self_number !== null"
          v-model:amount="entityEditable.min_distance_to_self_number"
          v-model:unit="entityEditable.min_distance_to_self_unit"
          label="Mindestabstand"
          v-bind:time-units="AppointmentTypeUnits"
          v-bind:errors="errorFor('min_distance_to_self_number')"
          v-bind:animation-status="statusFor('min_distance_to_self_number')"
          v-on="{ autosave: onAutosaveFor('min_distance_to_self_number') }"
        />
      </CheckboxAccordion>
      <FormCheckboxBitwise
        v-model="entityEditable.configs"
        label="für Privatpatienten"
        v-bind:flag="AppointmentTypeConfigs.private"
        v-on="{ autosave: onAutosaveFor('configs') }"
      />
      <FormCheckboxBitwise
        v-model="entityEditable.configs"
        label="für Kassenpatienten"
        v-bind:flag="AppointmentTypeConfigs.statutory"
        v-on="{ autosave: onAutosaveFor('configs') }"
      />
      <FormCheckboxBitwise
        v-model="entityEditable.configs"
        label="für Patienten über PraxisApp buchbar"
        v-bind:flag="AppointmentTypeConfigs.available_for_selection_by_patients"
        v-on="{ autosave: onAutosaveFor('configs') }"
      />
      <CheckboxAccordion
        v-bind:label="`Überschreibung der Standard-Praxiseinstellung für 'Einbestellzeit' (unter 'Allgemein' veränderbar)`"
        v-bind:show="entityEditable.order_time_in_minutes !== null"
        v-on:content:show="entityEditable.order_time_in_minutes = 0"
        v-on:content:hide="entityEditable.order_time_in_minutes = null"
        v-on:content:visibility-change="
          onAutosaveFor('booking_range_endn_number')()
        "
      >
        <FormInput
          v-if="entityEditable.order_time_in_minutes !== null"
          v-model="entityEditable.order_time_in_minutes"
          v-bind:type="'number'"
          label="Einbestellzeit der Patienten vor der Untersuchung"
          v-bind:errors="errorFor('order_time_in_minutes')"
          v-bind:animation-status="statusFor('order_time_in_minutes')"
          v-on="{ autosave: onAutosaveFor('order_time_in_minutes') }"
        />
      </CheckboxAccordion>
      <div class="sessions">
        <div
          v-for="session in sortedSessions"
          v-bind:key="session.id"
          class="session"
        >
          <FormInput
            v-model="session.name"
            label="Name"
            v-bind:errors="errorFor('name', `sessions.${session.id}`)"
            v-bind:animation-status="statusFor(`${session.id}.name`)"
            v-on="{ autosave: onAutosaveFor(`${session.id}.name`) }"
          />
          <FormInput
            v-model="session.duration"
            label="Dauer (in Minuten)"
            v-bind:errors="errorFor('duration', `sessions.${session.id}`)"
            v-bind:animation-status="statusFor(`${session.id}.duration`)"
            v-on="{ autosave: onAutosaveFor(`${session.id}.duration`) }"
          />
          <FormOfficeColor
            v-model="session.color_id"
            label="Farbe"
            v-bind:errors="errorFor('color_id', `sessions.${session.id}`)"
            v-bind:animation-status="statusFor(`${session.id}.color_id`)"
            v-on="{ autosave: onAutosaveFor(`${session.id}.color_id`) }"
          />
          <FormCheckboxGroup
            label="Rollen, die gleichzeitig  benötigt werden"
            v-bind:model-value="calculateEnabledRoles(session)"
            v-bind:options="roleOptions"
            v-bind:animation-status="statusFor(`${session.id}.roles`)"
            v-on="{
              autosave: onAutosaveFor(`${session.id}.roles`),
              'update:modelValue': onUpdateRoles(session),
            }"
          />
          <FormCheckboxGroup
            v-if="roomOptions.length > 0"
            v-model="session.room_ids"
            label="mögliche Räume"
            v-bind:options="roomOptions"
            v-bind:animation-status="statusFor(`${session.id}.room_ids`)"
            v-on="{
              autosave: onAutosaveFor(`${session.id}.room_ids`),
            }"
          />
          <span
            v-if="sortedSessions.length > 1"
            class="session__deletion-link"
            v-on:click="doDeleteSession(session)"
            >Einzeltermin löschen</span
          >
        </div>
        <div class="sessions__add" v-on:click="doAddAdditionalSession">
          <div class="sessions__add__icon">
            <i
              class="fe"
              v-bind:class="{
                'fe-plus--with-circle--inverted': sortedSessions.length !== 1,
                'fe-arrow-right': sortedSessions.length === 1,
              }"
            ></i>
          </div>
          <div class="sessions__add__text">
            <template v-if="sortedSessions.length < 1">
              Termineinstellungen hinzufügen
            </template>
            <template v-else-if="sortedSessions.length === 1">
              In Kettentermin umwandeln
            </template>
            <template v-else> Weiteren Einzeltermin hinzufügen </template>
          </div>
        </div>
      </div>

      <BaseButton
        class="action"
        v-bind:disabled="isPending"
        v-on:submit="deleteRecord"
      >
        <i class="fe fe-trash"></i>
      </BaseButton>
      <div class="eat__spacer"></div>
    </BaseAccordion>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .edit-appointment-type {
    margin-bottom: 15px;
  }

  .eat__header-open-text {
    display: inline-block;

    color: colors.$color_plain--text;
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    text-transform: none;

    margin-left: 20px;
  }

  .eat__header {
    margin: 10px 0;
  }

  .eat__time-pickers-line {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 20px;
    }
  }

  .eat__spacer {
    margin-bottom: 100px;
  }

  .sessions {
    display: flex;
    flex-wrap: wrap;
  }

  .session {
    max-width: 380px;
    margin-right: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-right: 1px solid colors.$color_settings-sessions--border;

    &:last-of-type {
      border-right: none;
    }
  }

  .session__deletion-link {
    color: colors.$color_link--text;
    font-size: 12px;
    cursor: pointer;
  }

  .sessions__add {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 300px;

    cursor: pointer;

    &:hover {
      &,
      i {
        color: colors.$color_link--text;
      }
    }
  }

  .sessions__add__icon {
    color: colors.$color_settings-sessions--icon;
    font-size: 50px;
  }

  .sessions__add__text {
    font-size: 14px;
    font-style: italic;
  }

  .sessions__add__icon,
  .sessions__add__text {
    text-align: center;
  }
</style>
