<script lang="ts">
  import { computed, defineComponent } from "vue";

  import { UserEntity } from "frontend/interfaces/user";
  import { useStore as useLoginStore } from "frontend/stores/login";
  import { useStore as useOfficeStore } from "frontend/stores/office";
  import { unblock } from "frontend/api/application/request-office";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import GeneralSearch from "frontend/components/GeneralSearch.vue";
  import BaseMenu from "frontend/components/base/BaseMenu.vue";
  import BaseLogo from "frontend/components/base/BaseLogo.vue";
  import OperatorSelector from "frontend/components/OperatorSelector.vue";

  export default defineComponent({
    components: {
      GeneralSearch,
      BaseButton,
      BaseMenu,
      BaseLogo,
      OperatorSelector,
    },
    props: {
      visualFixed: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const loginStore = useLoginStore();
      const isPractice = computed<boolean>(() => {
        return loginStore.isLoggedIn(UserEntity.Practice);
      });

      const officeStore = useOfficeStore();
      const unblockOffice = () => {
        unblock();
      };

      return {
        isPractice,
        officeStore,

        unblockOffice,

        doReloadPage: () => location.reload(),
      };
    },
  });
</script>

<template>
  <header class="header" v-bind:class="{ 'header--fixed': visualFixed }">
    <div class="header__left">
      <RouterLink v-bind:to="{ name: isPractice ? 'calendar' : 'patient' }">
        <BaseLogo />
      </RouterLink>
      <OperatorSelector v-if="isPractice" />
      <span
        v-if="officeStore.needRefresh"
        class="header__reloading"
        v-on:click="doReloadPage"
        >Neue Programmversion verfügbar</span
      >
    </div>
    <div v-if="isPractice" class="header__right">
      <BaseButton
        v-if="isPractice && officeStore.isBlocked"
        visual-warn
        visual-small
        v-on:submit="unblockOffice()"
      >
        Terminbuchung freigeben
      </BaseButton>
      <GeneralSearch
        class="header__search"
        v-bind:visual-dummy="!visualFixed"
      />
      <BaseMenu v-if="isPractice" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/dimensions";
  @use "shared/styles/colors";
  @use "frontend/styles/variables";

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    background-color: colors.$color_header--background;
    color: colors.$color_header--text;
    box-shadow: variables.$box-shadow_menus;
  }

  .header--fixed {
    position: fixed;

    left: 0;
    right: 0;
    top: 0;

    z-index: 100;
  }

  .header__right,
  .header__left {
    flex: 1;
    padding: 0 dimensions.$dimension_main--padding-lr;

    display: flex;
    align-items: center;

    min-height: 65px;
  }
  .header__left {
    margin-right: 20px;
  }

  .header__search {
    flex: 1;
    margin-right: 20px;
    min-width: 176px;
  }

  .header__reloading {
    margin-left: 20px;
    font-size: 12px;

    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
  @use "frontend/styles/dimensions";
  @use "shared/styles/colors";

  @media (max-width: 700px) {
    .header__right {
      width: 100%;
      background-color: colors.$color_header--background--contrast;
    }

    .header_search {
      width: 100%;
    }
  }

  @media (max-width: 460px) {
    .header__left {
      padding: 2vw dimensions.$dimension_main--padding-lr;
      min-height: initial;
    }
  }
</style>
