<script lang="ts">
  import {
    defineComponent,
    PropType,
    toRefs,
    unref,
    watch,
    nextTick,
  } from "vue";

  import {
    useFormSelect,
    FormSelectOption,
    FormSelectId,
  } from "frontend/uses/use-form-select";
  import { useFormLabelling } from "frontend/uses/use-form-labelling";
  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  export default defineComponent({
    components: { AnimationStatus },
    props: {
      options: {
        type: Array as PropType<Array<FormSelectOption>>,
        default: () => [],
      },
      label: {
        type: String,
        default: null,
      },
      modelValue: {
        type: [String, Number] as PropType<FormSelectId>,
        default: null,
      },
      includeBlank: {
        type: [Boolean, String],
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      errors: {
        type: Array as PropType<Errors>,
        default: () => [],
      },
      animationStatus: {
        type: Number as PropType<StatusValue | null>,
        default: null,
      },
      visualSmall: {
        type: Boolean,
        default: false,
      },
      visualInverted: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["update:modelValue", "autosave"],
    setup(props, { emit }) {
      const { modelValue, options } = toRefs(props);
      const { currentSelection } = useFormSelect(
        unref(modelValue),
        options,
        "update:modelValue",
        emit
      );
      let emitAutosave = true;
      watch(currentSelection, async () => {
        if (!emitAutosave) return;
        await nextTick(); // wait for update of model-value
        emit("autosave");
      });
      watch(modelValue, async () => {
        if (modelValue.value === currentSelection.value) return;
        emitAutosave = false;
        currentSelection.value = modelValue.value;
        await nextTick();
        emitAutosave = true;
      });
      const { pairing } = useFormLabelling();
      return { currentSelection, pairing };
    },
  });
</script>

<template>
  <div
    class="form-select"
    v-bind:class="{
      'form-select--small': visualSmall,
      'form-select--inverted': visualInverted,
      'form-select--no-label': !label,
    }"
  >
    <label v-if="label" class="form-select__label" v-bind:for="pairing">{{
      label
    }}</label>
    <div class="form-select__status-container">
      <select
        v-bind:id="pairing"
        v-model="currentSelection"
        class="form-select__select"
        v-bind:disabled="disabled"
      >
        <option
          v-if="!includeBlank"
          class="form-select__text"
          v-bind:disabled="modelValue != null"
          v-bind:value="null"
        >
          Bitte auswählen:
        </option>
        <option
          v-if="includeBlank"
          class="form-select__option"
          v-bind:value="null"
        >
          <template v-if="typeof includeBlank === 'string'">{{
            includeBlank
          }}</template>
          <template v-else>-- Keine Auswahl --</template>
        </option>
        <option
          v-for="option in options"
          v-bind:key="option.id"
          class="form-select__option"
          v-bind:value="option.id"
        >
          {{ option.label }}
        </option>
      </select>
      <AnimationStatus
        v-if="animationStatus !== null"
        class="form-select__animation-status"
        v-bind:animation-status="animationStatus"
      />
    </div>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="form-select__label__error"
        >{{ error }}</label
      >
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  .form-select {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .form-select__label,
  .form-select__label__error {
    @include label.label;
  }

  .form-select__label__error {
    @include label.label--error;
  }

  .form-select__select {
    background-color: colors.$color_form-select--background;
    border: none;
    padding: 10px;
    border-radius: 3px;
    color: colors.$color_form-select--text;
    font-weight: bold;
    font-size: 16px;
  }

  .form-select__text {
    background-color: colors.$color_form-select__chooser--background;
    color: colors.$color_form-select__chooser--text;
    border: none;
    border-bottom: 1px solid colors.$color_form-select--text;
  }

  .form-select__option {
    background-color: colors.$color_form-select__option--background;
    border: none;
    padding: 13px;
  }

  // ANIMATION-STATUS:
  .form-select__status-container {
    display: flex;
    align-items: center;
  }

  .form-select__animation-status {
    margin-left: 10px;
    margin-top: 3px;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .form-select__select {
    flex: 1;
    max-width: 100%;
  }

  .form-select--small {
    .form-select__select {
      font-size: 12px;
      padding: 5px;
    }
  }

  .form-select--inverted {
    .form-select__select,
    .form-select__option {
      color: colors.$color_form-select--background;
      background-color: colors.$color_form-select--text;
    }
  }

  .form-select--no-label {
    padding: 0;
  }
</style>
