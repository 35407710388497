<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { Suggestion } from "frontend/interfaces/p/suggestion";
  import { Patient } from "frontend/interfaces/patient";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { AppointmentType } from "frontend/interfaces/p/appointment-type";

  import BaseBox from "frontend/components/base/BaseBox.vue";
  import PatientProfile from "frontend/components/PatientProfile.vue";

  export default defineComponent({
    components: { BaseBox, PatientProfile },
    props: {
      patient: {
        type: Object as PropType<Patient>,
        required: true,
      },
      suggestion: {
        type: Object as PropType<Suggestion>,
        required: true,
      },
      appointmentType: {
        type: Object as PropType<AppointmentType>,
        required: true,
      },
    },
    setup() {
      return {
        formatDate,
        DateFormat,
      };
    },
  });
</script>

<template>
  <BaseBox visual-no-heading>
    <div class="appointment-box__container">
      <i class="fe fe-calendar-day appointment-icon__calendar"></i>
      <div>
        <div class="appointment__date">
          <strong>
            {{ formatDate(suggestion.begin, DateFormat.DateOnlyLong) }},
            {{
              formatDate(suggestion.begin, DateFormat.TimeOnlyWithoutSeconds)
            }}
          </strong>
        </div>
        <div>Besuchsgrund: {{ appointmentType.name }}</div>
      </div>
    </div>
    <PatientProfile v-bind:patient="patient" />
  </BaseBox>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";

  .appointment-box__container {
    background-color: colors.$color_main-area--background;
    color: colors.$color_create-appointment__box-new--text;
    padding: 10px;
    display: flex;
  }

  .appointment-icon__calendar {
    font-size: 20px;
    padding-right: 10px;
  }

  .appointment__date {
    margin-bottom: 5px;
  }
</style>
