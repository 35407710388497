<script lang="ts">
  import { defineComponent } from "vue";

  import logoPath from "shared/assets/logo.svg";

  export default defineComponent({
    setup() {
      return {
        logoPath,
      };
    },
  });
</script>

<template>
  <div class="vue-component__base-logo brand">
    <img class="brand__logo" v-bind:src="logoPath" alt="logo" />
    <span class="brand__name">Online-Terminverwaltung</span>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "frontend/styles/features";

  .brand {
    // positioning inside
    display: flex;
    align-items: center;
  }

  .brand__name {
    // visual style
    font-size: 27px;

    @if features.$feature_branding--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
    font-family: "Open Sans Condensed", sans-serif;
    color: colors.$color_brand-name--text;

    white-space: nowrap;
  }

  .brand__logo {
    height: 40px;
    margin-right: 10px;
  }
</style>

<style lang="scss" scoped>
  @media (max-width: 460px) {
    .brand__name {
      font-size: 6vw;
    }

    .brand__logo {
      height: 8vw;
      margin-right: 2vw;
    }
  }
</style>
